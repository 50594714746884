/*-------------------------
   About Us
-------------------------*/

.contact-form-box {
    background-color: var(--color-white);
    padding: 50px;
    border-radius: 30px;
    @media #{$sm-layout} {
        padding: 40px 30px;
    }
    .title {
        font-size: 40px;
        text-align: center;
        @media #{$laptop-device} {
            font-size: 36px;
        }
        @media #{$smlg-device} {
            font-size: 32px;
        }
        @media #{$sm-layout} {
            font-size: 28px;
        }
    }
    p {
        text-align: center;
        font-size: 18px;
    }
    .form-group {
        padding-top: 15px;
        .axil-btn {
            width: 50vh;
            @media #{$sm-layout} {
                width: 25vh;
            }
            &::after {
                width: calc(100% + 50px);
            }
        }
    }
    &.shadow-box {
        box-shadow: 0 20px 48px 0 rgba(153,161,170,.25);
    }
    .btn-submit {
        display: flex;
        justify-content: center;
    }
    .text-area {
        height: 16vh;
    }
}