/*-------------------------
    Banner
-------------------------*/
.banner {
    padding: 255px 0 100px;
    margin-bottom: 80px;
    position: relative;
    z-index: 2;
    min-height: 800px;
    @media #{$laptop-device} {
        padding: 220px 0 0;
    }
    @media #{$smlg-device} {
        padding: 200px 0 0;
        min-height: 100%;
    }
    
    @media #{$md-layout} {
        padding: 160px 0 0;
    } 
    @media #{$sm-layout} {
        padding: 150px 0 0;
    }

    .banner-content {
        @media #{$md-layout}{
            margin-bottom: 90px;
        }
        @media #{$sm-layout}{
            margin-bottom: 60px;
        }
        @media #{$large-mobile}{
            margin-bottom: 40px;
        }
        .title {
            font-size: 80px;
            font-weight: 700;
            color: var(--color-dark);
            @media #{$laptop-device} {
                font-size: 68px;
            }
            @media #{$smlg-device} {
                font-size: 60px;
            }
            @media #{$md-layout} {
                font-size: 55px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
            }
        }
        .subtitle {
            font-size: var(--font-body-1);
            margin-bottom: 40px;
            width: 90%;
        }
        .axil-btn {
            padding: 28px 45px;
            box-shadow: 0 44px 84px -24px #5956e9;
            @media #{$md-layout} {
                padding: 20px 35px;
            }
        }
    }
    .banner-thumbnail {
        position: relative;
    }
    &.banner-style-1 {
        .banner-content {
            margin-top: 60px;
            @media #{$smlg-device} {
                padding-bottom: 100px;
            }
            @media #{$md-layout} {
                margin-top: 0;
                padding-bottom: 0;
            }
            .title {
                font-size: 78px;
                line-height: 1;
                @media #{$laptop-device} {
                    font-size: 68px;
                }
                @media #{$smlg-device} {
                    font-size: 60px;
                }
                @media #{$md-layout} {
                    font-size: 55px;
                }
                @media #{$sm-layout} {
                    font-size: 40px;
                }
            }
        }
        .banner-thumbnail {
            z-index: 5;
            .large-thumb {
                @media #{$laptop-device} {
                    img {
                        width: 74%;
                    }
                }
                @media #{$sm-layout} {
                    // display: none;
                }
            }
            .large-thumb-2 {
                text-align: right;
                margin-top: -260px;
                margin-right: -50px;
                @media #{$laptop-device} {
                    img {
                        width: 85%;
                    }
                }
                @media #{$smlg-device} {
                    margin-top: -200px;
                    margin-right: -30px;
                    img {
                        width: 80%;
                    }
                }
                @media #{$md-layout} {
                    margin-top: -260px;
                    margin-right: 0;
                }
                @media #{$sm-layout} {
                    margin-top: 0;
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                }
            }
            .shape-group {
                .shape {
                    position: absolute;
                    &.shape-1 {
                        top: -25px;
                        right: 20px;
                        left: auto;
                        @media #{$laptop-device} {
                            right: 0;
                        }
                        @media #{$smlg-device} {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}