@charset 'UTF-8';
/* Slider */

/* Icons */
/* @font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
} */
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 1em;
    line-height: 0;

    position: absolute;
    top: 115%;
    right: 100%;

    display: block;

    width: 13vh;
    height: 50px;
    padding: 15px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: #999;
    border: 0;
    border-radius: 10px;
    outline: none;
    background: #fff;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    /* color: transparent;
    outline: none;
    background: transparent; */
}
/* .slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
} */

/* .slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */

.slick-next
{
    right: 21%;
    width: 13vh;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
/* .slick-prev:before
{
    content: '← Previous';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
} */

.slick-prev
{
    right: 13%;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
/* .slick-next:before
{
    content: '→';
} */
/* [dir='rtl'] .slick-next:before
{
    content: '←';
} */

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 90%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 100px;

    position: absolute;
    top: 0;
    left: 0;

    width: 50px;
    height: 50px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: #fff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: #fff;
}
@media only screen and (max-width: 600px) {
    .slick-prev,
    .slick-next{
        top: 110%;
        width: 8vh;
        height: 35px;
        padding: 5px 5px;
        font-size: 0.7em;
    }
    .slick-next{
    right: 8%;
    width: 8vh;
    }
    .slick-prev{
        right: 28%;
    }
}
/* @media only screen and (max-width: 950px) {
    .slick-prev,
    .slick-next{
        width: 10vh;
        height: 35px;
        padding: 5px 5px;
        font-size: 0.8em;
    }
    .slick-next{
    right: 25%;
    width: 10vh;
    }
    .slick-prev{
        right: 9%;
    }
} */
/* @media only screen and (max-width: 1300px) {
    .slick-prev,
    .slick-next{
        width: 10vh;
        height: 35px;
        padding: 5px 5px;
        font-size: 0.8em;
    }
    .slick-next{
    right: 13%;
    width: 10vh;
    }
    .slick-prev{
        right: 5%;
    }
} */