@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;

    margin: 0;
    /* margin-left: 31vh; */
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    width: 95%; 
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */

/* Icons */
/* @font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
} */
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 1em;
    line-height: 0;

    position: absolute;
    top: 115%;
    right: 100%;

    display: block;

    width: 13vh;
    height: 50px;
    padding: 15px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: #999;
    border: 0;
    border-radius: 10px;
    outline: none;
    background: #fff;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    /* color: transparent;
    outline: none;
    background: transparent; */
}
/* .slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
} */

/* .slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */

.slick-next
{
    right: 21%;
    width: 13vh;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
/* .slick-prev:before
{
    content: '← Previous';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
} */

.slick-prev
{
    right: 13%;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
/* .slick-next:before
{
    content: '→';
} */
/* [dir='rtl'] .slick-next:before
{
    content: '←';
} */

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 90%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 100px;

    position: absolute;
    top: 0;
    left: 0;

    width: 50px;
    height: 50px;

    content: '\2022';
    text-align: center;

    opacity: .25;
    color: #fff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: #fff;
}
@media only screen and (max-width: 600px) {
    .slick-prev,
    .slick-next{
        top: 110%;
        width: 8vh;
        height: 35px;
        padding: 5px 5px;
        font-size: 0.7em;
    }
    .slick-next{
    right: 8%;
    width: 8vh;
    }
    .slick-prev{
        right: 28%;
    }
}
/* @media only screen and (max-width: 950px) {
    .slick-prev,
    .slick-next{
        width: 10vh;
        height: 35px;
        padding: 5px 5px;
        font-size: 0.8em;
    }
    .slick-next{
    right: 25%;
    width: 10vh;
    }
    .slick-prev{
        right: 9%;
    }
} */
/* @media only screen and (max-width: 1300px) {
    .slick-prev,
    .slick-next{
        width: 10vh;
        height: 35px;
        padding: 5px 5px;
        font-size: 0.8em;
    }
    .slick-next{
    right: 13%;
    width: 10vh;
    }
    .slick-prev{
        right: 5%;
    }
} */
/*---------------------------------------------

===============================================   
STYLESHEET INDEXING
|
|___ Default Styles
|	|___ Variables
|	|___ Reset Styels
|	|___ Typography
|	|___ Forms Styles
|	|___ Helper Classes 
|
|___ Elements Styles
|	|___ Back To Top
|	|___ Breadcrumb
|	|___ Button
|	|___ Pagination 
|	|___ Section Heading
|	|___ Slick 
|
|___Template Styles
|	|___ About Us
|	|___ Banner
|	|___ Brand
|	|___ Call To Action
|	|___ Case Study
|	|___ Contact
|	|___ Counter Up
|	|___ FAQ
|	|___ Pricing
|	|___ Process
|	|___ Project
|	|___ Service
|	|___ Team
|	|___ Testimonial
|	|___  Why Choose
|	|___  404 Error
|
|___Blocks Styles
|	|___ Header Styles
|	|___ Blog Styles
|	|___ Footer Styles
|
|___Dark Style
|
|___Spacing
|
|___RTL
|
|___ END STYLESHEET INDEXING

--------------------------------------------*/
/*=======================================================================
1. Default Styles
=========================================================================*/
/*-------------------------
    Variables
-------------------------*/
:root {
  --color-primary: #ff4338;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #ECF2F6;
  --color-dark: #27272E;
  --color-text-dark: #292930;
  --color-accent1: #FFDC60;
  --color-accent2: #FAB8C4;
  --color-blue-shade: #6865FF;
  --color-link: #f82e23;
  --color-mabel: #DBF8FF;
  --color-fog: #DBDEFF;
  --color-pink-shade: #FFD3DB;
  --color-peach: #FFEDDC;
  --color-rose: #C75C6F;
  --color-chart1: #896BA7;
  --color-chart2: #BADEFF;
  --color-body: #525260;
  --color-gray-1: #757589;
  --color-gray-2: #999FAE;
  --color-gray-3: #999AB8;
  --color-gray-4: #99A1AA;
  --color-ship-gray: #42424A;
  --color-ghost: #C7C7D5;
  --color-mercury: #E5E5E5;
  --color-design: #5856D6;
  --color-mobile: #33CCFF;
  --color-web-dev:#f79c47;
  --color-devOps: #34C759;
  --gradient-primary: linear-gradient(90deg, #ECF2F6 0%, rgba(236, 242, 246, 0) 70.31%);
  --gradient-blue: linear-gradient(145.92deg, #5956E9 20.18%, #9991FF 76.9%);
  --gradient-accent: linear-gradient(180deg, #FAB8C4 0%, #FFEDF0 100%);
  --gradient-white: linear-gradient(266.3deg, rgba(236, 242, 246, 0) 7.84%, #ECF2F6 29.1%, rgba(236, 242, 246, 0) 64.32%);
  --gradient-dark: linear-gradient(180deg, #27272E 0%, #303035 100%);
  --border-light: 1px solid #E3E6E9;
  --border-lighter: 1px solid #ECF2F6;
  --border-dark: 1px solid var(--color-ship-gray);
  --border-gray: 1px solid var(--color-gray-4);
  --font-primary: "DM Sans", sans-serif;
  --font-secondary: "Poppins", sans-serif;
  --font-awesome: "Font Awesome 5 Pro";
  --transition: all 0.3s ease-in-out;
  --font-body-1: 18px;
  --font-body-2: 16px;
  --font-body-3: 14px;
  --font-body-4: 12px;
  --line-height-b1: 1.5;
  --line-height-b3: 1.1;
  --h1: 80px;
  --h2: 64px;
  --h3: 48px;
  --h4: 32px;
  --h5: 24px;
  --h6: 20px;
}

/*-------------------------
    Reset Styels
-------------------------*/
a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}

span {
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ul.list-style,
ol.list-style {
  margin: -10px 0;
}
ul.list-style li,
ol.list-style li {
  margin: 10px 0;
}
ul.list-style li::marker,
ol.list-style li::marker {
  color: #ff4338;
  color: var(--color-primary);
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .container {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row > [class*=col] {
  padding-left: 15px;
  padding-right: 15px;
}
.row.g-0 {
  margin-left: 0;
  margin-right: 0;
}
.row.g-0 > [class*=col] {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 1400px) {
  .row-35 {
    margin-left: -35px;
    margin-right: -35px;
  }
  .row-35 > [class*=col] {
    padding-left: 35px;
    padding-right: 35px;
  }

  .row-40 {
    margin-left: -40px;
    margin-right: -40px;
  }
  .row-40 > [class*=col] {
    padding-left: 40px;
    padding-right: 40px;
  }

  .row-45 {
    margin-left: -45px;
    margin-right: -45px;
  }
  .row-45 > [class*=col] {
    padding-left: 45px;
    padding-right: 45px;
  }
}
/*-------------------------
    Typography
-------------------------*/
body {
  font-family: "Poppins", sans-serif;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-size: var(--font-body-2);
  line-height: 1.5;
  line-height: var(--line-height-b1);
  color: #525260;
  color: var(--color-body);
  font-weight: 400;
  height: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
table,
hr {
  margin: 0 0 30px 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans", sans-serif;
  font-family: var(--font-primary);
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #292930;
  color: var(--color-text-dark);
}

h1,
.h1 {
  font-size: 80px;
  font-size: var(--h1);
  line-height: 1.2;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  h1,
.h1 {
    font-size: 68px;
  }
}
@media only screen and (max-width: 1199px) {
  h1,
.h1 {
    font-size: 68px;
  }
}
@media only screen and (max-width: 767px) {
  h1,
.h1 {
    font-size: 48px;
  }
}

h2,
.h2 {
  font-size: 64px;
  font-size: var(--h2);
  line-height: 1.2;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  h2,
.h2 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 1199px) {
  h2,
.h2 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 767px) {
  h2,
.h2 {
    font-size: 38px;
  }
}
@media only screen and (max-width: 479px) {
  h2,
.h2 {
    font-size: 34px;
  }
}

h3,
.h3 {
  font-size: 48px;
  font-size: var(--h3);
  line-height: 1.2;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  h3,
.h3 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 1199px) {
  h3,
.h3 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  h3,
.h3 {
    font-size: 32px;
  }
}

h4,
.h4 {
  font-size: 32px;
  font-size: var(--h4);
  line-height: 1.2;
}
@media only screen and (max-width: 991px) {
  h4,
.h4 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  h4,
.h4 {
    font-size: 20px;
  }
}

h5,
.h5 {
  font-size: 24px;
  font-size: var(--h5);
  line-height: 1.4;
}
@media only screen and (max-width: 767px) {
  h5,
.h5 {
    font-size: 20px;
  }
}

h6,
.h6 {
  font-size: 20px;
  font-size: var(--h6);
  line-height: 1.2;
}
@media only screen and (max-width: 767px) {
  h6,
.h6 {
    font-size: 18px;
  }
}

p {
  font-size: 16px;
  font-size: var(--font-body-2);
  line-height: 1.5;
  line-height: var(--line-height-b1);
  margin: 0 0 40px 0;
}

.body-font1 {
  font-size: 18px;
  font-size: var(--font-body-1);
  line-height: 1.5;
  line-height: var(--line-height-b1);
}

.body-font2 {
  font-size: 16px;
  font-size: var(--font-body-2);
  line-height: 1.5;
  line-height: var(--line-height-b1);
}

.body-font3 {
  font-size: 14px;
  font-size: var(--font-body-3);
  line-height: 1.1;
  line-height: var(--line-height-b3);
}

.body-font4 {
  font-size: 12px;
  font-size: var(--font-body-4);
  line-height: 1.1;
  line-height: var(--line-height-b3);
}

/*-------------------------
    Forms Styles
-------------------------*/
input,
.form-control {
  border: 1px solid #ECF2F6;
  border: var(--border-lighter);
  border-radius: 16px;
  background-color: transparent;
  color: #292930;
  color: var(--color-text-dark);
  padding: 15px 20px;
  width: 100%;
  /* -- Placeholder -- */
}
input:focus,
.form-control:focus {
  outline: none;
  box-shadow: 0 20px 48px -14px rgba(153, 161, 170, 0.25);
  border-color: transparent;
}
input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
  color: #999FAE;
  color: var(--color-gray-2);
  /* Firefox */
  opacity: 1;
}
input:-ms-input-placeholder, .form-control:-ms-input-placeholder {
  color: #999FAE;
  color: var(--color-gray-2);
  /* Firefox */
  opacity: 1;
}
input::placeholder,
.form-control::placeholder {
  color: #999FAE;
  color: var(--color-gray-2);
  /* Firefox */
  opacity: 1;
}
input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999FAE;
  color: var(--color-gray-2);
}
input::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999FAE;
  color: var(--color-gray-2);
}

input {
  height: 60px;
}

.form-group {
  margin-bottom: 16px;
}
.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #27272E;
  color: var(--color-dark);
  margin-bottom: 10px;
}

.input-group {
  background-color: #ffffff;
  background-color: var(--color-white);
  border-radius: 20px;
  height: 72px;
  padding: 10px 10px 10px 55px;
  box-shadow: 0 24px 48px -15px rgba(153, 161, 170, 0.25);
}
@media only screen and (max-width: 479px) {
  .input-group {
    display: block;
    box-shadow: none;
    height: auto;
    padding: 0;
  }
}
.input-group .mail-icon {
  position: absolute;
  left: 30px;
  top: 22px;
  z-index: 1;
}
@media only screen and (max-width: 479px) {
  .input-group .mail-icon {
    left: 15px;
    top: 16px;
  }
}
.input-group .form-control {
  padding: 5px 15px;
  height: auto;
  border: none;
  background-color: transparent;
}
.input-group .form-control:focus {
  box-shadow: none;
}
@media only screen and (max-width: 479px) {
  .input-group .form-control {
    height: 60px;
    width: 100%;
    border-radius: 20px !important;
    padding: 5px 15px 5px 50px;
    border: 1px solid #ECF2F6;
    border: 1px solid var(--color-light);
  }
}
.input-group .subscribe-btn {
  border: none;
  background-color: #ff4338;
  background-color: var(--color-primary);
  color: #ffffff;
  color: var(--color-white);
  border-radius: 16px !important;
  margin-left: 0 !important;
  padding: 10px 28px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.input-group .subscribe-btn:hover {
  background-color: #f82e23;
  background-color: var(--color-link);
}
@media only screen and (max-width: 479px) {
  .input-group .subscribe-btn {
    margin-top: 15px;
    padding: 14px 30px;
  }
}

.error-msg, .success-msg {
  margin-top: 25px;
}
.error-msg p, .success-msg p {
  margin-bottom: 0;
  font-size: 14px;
}

.error-msg {
  color: #ff0000;
}

.success-msg {
  color: #15c54b;
  margin-bottom: 0;
}

/*-------------------------
  Helper Classes
-------------------------*/
.bg-color-dark {
  background-color: #27272E;
  background-color: var(--color-dark);
}

.bg-color-light {
  background-color: #ECF2F6;
  background-color: var(--color-light);
}

.bg-color-mercury {
  background-color: #E5E5E5;
  background-color: var(--color-mercury);
}

.bg-color-ship-gray {
  background-color: #42424A;
  background-color: var(--color-ship-gray);
}

.color-gray-4 {
  color: #99A1AA;
  color: var(--color-gray-4);
}

.opacity-50 {
  opacity: 0.5;
}

/*=======================================================================
2. Elements Styles
=========================================================================*/
/*-------------------------
    Button
-------------------------*/
.axil-btn {
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  padding: 18px 55px;
  height: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  position: relative;
  z-index: 1;
}
.axil-btn.btn-borderd {
  border: 1px solid #99A1AA;
  border: var(--border-gray);
  color: #27272E;
  color: var(--color-dark);
  background-color: transparent;
}
.axil-btn.btn-borderd:hover {
  background-color: #ff4338;
  background-color: var(--color-primary);
  color: #ffffff;
  color: var(--color-white);
  border-color: #ff4338;
  border-color: var(--color-primary);
}
.axil-btn.btn-fill-primary {
  background-color: #ff4338;
  background-color: var(--color-primary);
  color: #ffffff;
  color: var(--color-white);
  border: 1px solid #ff4338;
  border: 1px solid var(--color-primary);
  overflow: hidden;
}
.axil-btn.btn-fill-primary::after {
  content: "";
  height: 300px;
  width: 300px;
  background-color: #f82e23;
  background-color: var(--color-link);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%) scale(0);
          transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;
}
.axil-btn.btn-fill-primary:hover {
  background-color: #f82e23;
  background-color: var(--color-link);
  border-color: #f82e23;
  border-color: var(--color-link);
}
.axil-btn.btn-fill-primary:hover::after {
  -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
          transform: translateY(-50%) translateX(-50%) scale(1);
}
.axil-btn.btn-fill-white {
  background-color: #ffffff;
  background-color: var(--color-white);
  box-shadow: 0px 48px 48px 0px rgba(0, 0, 0, 0.12);
}
.axil-btn.btn-fill-white:hover {
  background-color: #FFDC60;
  background-color: var(--color-accent1);
  color: #ffffff;
  color: var(--color-white);
}
.axil-btn.btn-large {
  padding: 23px 55px;
}
@media only screen and (max-width: 991px) {
  .axil-btn.btn-large {
    padding: 18px 35px;
  }
}
.axil-btn.btn-large-fluid {
  padding: 23px;
  width: 100%;
}
.axil-btn.btn-fluid {
  width: 100%;
}

/*-------------------------
    Section Heading
-------------------------*/
.section {
  position: relative;
  z-index: 1;
}

.section-heading {
  text-align: center;
  margin-bottom: 60px;
}
@media only screen and (max-width: 991px) {
  .section-heading {
    margin-bottom: 50px;
  }
}
.section-heading .subtitle {
  color: #C75C6F;
  color: var(--color-rose);
  font-weight: 500;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  font-family: var(--font-primary);
  margin-bottom: 15px;
}
@media only screen and (max-width: 991px) {
  .section-heading .subtitle {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .section-heading .subtitle {
    font-size: 16px;
  }
}
.section-heading .subtitle span {
  margin-right: 5px;
  position: relative;
}
.section-heading .subtitle span:after {
  content: ",";
}
.section-heading .subtitle span:last-child {
  margin-right: 0;
}
.section-heading .subtitle span:last-child:after {
  display: none;
}
@media only screen and (max-width: 1199px) {
  .section-heading .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .section-heading .title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .section-heading .title {
    font-size: 38px;
  }
}
@media only screen and (max-width: 479px) {
  .section-heading .title {
    font-size: 34px;
  }
}
.section-heading p {
  font-size: 18px;
  font-size: var(--font-body-1);
  width: 50%;
  margin: 0 auto 40px;
}
@media only screen and (max-width: 991px) {
  .section-heading p {
    font-size: 16px;
    width: 100%;
  }
}

.heading-light-left {
  text-align: left;
}
.heading-light-left .title {
  color: #ffffff;
  color: var(--color-white);
  margin-bottom: 35px;
  width: 50%;
}
@media only screen and (max-width: 1199px) {
  .heading-light-left .title {
    width: 70%;
  }
}
@media only screen and (max-width: 991px) {
  .heading-light-left .title {
    width: 100%;
  }
}
.heading-light-left p {
  color: #ffffff;
  color: var(--color-white);
  margin: 0 0 40px;
  width: 40%;
  opacity: 0.6;
}
@media only screen and (max-width: 1199px) {
  .heading-light-left p {
    width: 60%;
  }
}
@media only screen and (max-width: 991px) {
  .heading-light-left p {
    width: 100%;
  }
}

.heading-light .title {
  color: #ffffff;
  color: var(--color-white);
}
.heading-light p {
  color: #99A1AA;
  color: var(--color-gray-4);
}

.heading-left {
  text-align: left;
}
.heading-left p {
  margin: 0 0 30px;
}
.heading-left p + p {
  margin: 0 0 40px;
}

.shape-group-1 .shape {
  position: absolute;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-1 .shape img {
    opacity: 0.4;
  }
}
@media only screen and (max-width: 991px) {
  .shape-group-1 .shape img {
    opacity: 0.4;
  }
}
.shape-group-1 .shape.shape-1 {
  top: 140px;
  left: 315px;
}
.shape-group-1 .shape.shape-2 {
  top: 120px;
  right: 0;
}
.shape-group-1 .shape.shape-3 {
  bottom: 80px;
  left: 0;
}
.shape-group-1 .shape.shape-4 {
  bottom: 60px;
  right: 230px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-1 .shape.shape-4 {
    right: 100px;
  }
}

.shape-group-2 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-2 .shape.shape-1 {
  bottom: 0;
  right: 135px;
}
.shape-group-2 .shape.shape-2 {
  right: 500px;
  top: 0;
}
.shape-group-2 .shape.shape-3 {
  right: 815px;
  top: 125px;
}

.shape-group-3 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-3 .shape.shape-1 {
  right: 0;
  top: 90px;
}
.shape-group-3 .shape.shape-2 {
  right: 390px;
  top: 165px;
}
.shape-group-3 .shape.shape-3 {
  right: 0;
  top: 255px;
  z-index: 2;
  pointer-events: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-3 .shape.shape-3 {
    display: none;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-3 .shape.shape-3 {
    display: none;
  }
}

.shape-group-4 .shape-1 {
  position: absolute;
  top: 140px;
  right: 440px;
  z-index: -1;
}

.shape-group-5 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-5 .shape.shape-1 {
  top: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  left: 22%;
}
.shape-group-5 .shape.shape-2 {
  bottom: 0;
  left: 250px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.shape-group-5 .shape.shape-3 {
  bottom: 110px;
  left: 160px;
}
.shape-group-5 .shape.shape-4 {
  top: 90px;
  left: 35%;
}

.shape-group-6 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-6 .shape.shape-1 {
  top: 0;
  left: 0;
}
.shape-group-6 .shape.shape-2 {
  top: 110px;
  left: 0;
}
.shape-group-6 .shape.shape-3 {
  bottom: 140px;
  right: 0;
}
.shape-group-6 .shape.shape-4 {
  bottom: -1px;
  left: 11%;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-6 .shape.shape-4 {
    left: 0;
  }
  .shape-group-6 .shape.shape-4 img {
    width: 90%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-6 .shape.shape-4 {
    left: 0;
  }
  .shape-group-6 .shape.shape-4 img {
    width: 70%;
  }
}

.shape-group-7 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-7 .shape.shape-1 {
  top: 90px;
  right: 200px;
}
.shape-group-7 .shape.shape-2 {
  top: 210px;
  left: 52%;
}
@media only screen and (max-width: 991px) {
  .shape-group-7 .shape.shape-2 {
    display: none;
  }
}
.shape-group-7 .shape.shape-3 {
  top: 40%;
  left: 170px;
}

.shape-group-8 .shape {
  position: absolute;
  z-index: -1;
  pointer-events: none;
}
.shape-group-8 .shape.shape-1 {
  bottom: 0;
  left: 0;
}
@media only screen and (max-width: 767px) {
  .shape-group-8 .shape.shape-1 {
    display: none;
  }
}
.shape-group-8 .shape.shape-2 {
  top: 0;
  right: 0;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-8 .shape.shape-2 img {
    width: 90%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-8 .shape.shape-2 img {
    width: 80%;
  }
}
@media only screen and (max-width: 767px) {
  .shape-group-8 .shape.shape-2 img {
    width: 60%;
  }
}
.shape-group-8 .shape.shape-3 {
  top: 115px;
  left: 0;
}

.shape-group-9 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-9 .shape.shape-1 {
  top: 0;
  left: 32%;
}
.shape-group-9 .shape.shape-2 {
  top: 180px;
  left: 18%;
  opacity: 0.5;
  -webkit-animation: movingleftright1 infinite 20000ms;
          animation: movingleftright1 infinite 20000ms;
}
.shape-group-9 .shape.shape-3 {
  top: 31%;
  left: 20%;
  -webkit-animation: movingleftright2 infinite 25000ms;
          animation: movingleftright2 infinite 25000ms;
}
.shape-group-9 .shape.shape-4 {
  top: 44%;
  left: 28%;
  -webkit-animation: movingleftright1 infinite 25000ms;
          animation: movingleftright1 infinite 25000ms;
}
.shape-group-9 .shape.shape-5 {
  top: 50%;
  left: 17%;
  -webkit-animation: movingleftright2 infinite 30000ms;
          animation: movingleftright2 infinite 30000ms;
}
.shape-group-9 .shape.shape-6 {
  top: 26%;
  right: 21%;
  -webkit-animation: movingleftright3 infinite 30000ms;
          animation: movingleftright3 infinite 30000ms;
}
@media only screen and (max-width: 767px) {
  .shape-group-9 .shape.shape-6 {
    display: none;
  }
}
.shape-group-9 .shape.shape-7 {
  top: 30%;
  right: 15%;
  -webkit-animation: movingleftright1 infinite 30000ms;
          animation: movingleftright1 infinite 30000ms;
}

.shape-group-10 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-10 .shape.shape-1 {
  top: 100px;
  right: 0;
}
.shape-group-10 .shape.shape-2 {
  bottom: 200px;
  left: 0;
}
.shape-group-10 .shape.shape-3 {
  top: 680px;
  left: 110px;
}

.shape-group-11 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-11 .shape.shape-1 {
  top: 40px;
  right: 0;
}
.shape-group-11 .shape.shape-2 {
  bottom: -310px;
  left: -10px;
  opacity: 0.4;
}

.shape-group-12 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-12 .shape.shape-1 {
  top: 115px;
  left: 38%;
}
.shape-group-12 .shape.shape-2 {
  left: 60px;
  bottom: 130px;
}
.shape-group-12 .shape.shape-3 {
  right: 60px;
  bottom: 50px;
}

.shape-group-13 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-13 .shape.shape-1 {
  top: 0;
  left: 0;
}
@media only screen and (max-width: 991px) {
  .shape-group-13 .shape.shape-1 img {
    width: 60%;
  }
}
.shape-group-13 .shape.shape-2 {
  top: 0;
  right: -5px;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-13 .shape.shape-2 img {
    width: 80%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-13 .shape.shape-2 img {
    width: 75%;
  }
}
@media only screen and (max-width: 991px) {
  .shape-group-13 .shape.shape-2 img {
    width: 50%;
  }
}
@media only screen and (max-width: 479px) {
  .shape-group-13 .shape.shape-2 img {
    width: 40%;
  }
}
.shape-group-13 .shape.shape-3 {
  right: -10px;
  bottom: 62px;
  z-index: 2;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-13 .shape.shape-3 {
    bottom: 220px;
  }
  .shape-group-13 .shape.shape-3 img {
    width: 60%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-13 .shape.shape-3 {
    bottom: 100px;
  }
  .shape-group-13 .shape.shape-3 img {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .shape-group-13 .shape.shape-3 img {
    width: 40%;
  }
}
.shape-group-13 .shape.shape-4 {
  left: 220px;
  top: 45%;
  height: 48px;
  width: 48px;
  -webkit-animation: movingleftright1 infinite 25000ms;
          animation: movingleftright1 infinite 25000ms;
}
.shape-group-13 .shape.shape-5 {
  left: 45%;
  bottom: 130px;
  height: 32px;
  width: 32px;
  -webkit-animation: movingleftright3 infinite 20000ms;
          animation: movingleftright3 infinite 20000ms;
}

.shape-group-14 .shape {
  position: absolute;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-14 .shape img {
    width: 70%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-14 .shape img {
    width: 40%;
  }
}
.shape-group-14 .shape.shape-1 {
  left: 40%;
  bottom: 230px;
  -webkit-animation: movingleftright1 infinite 20000ms;
          animation: movingleftright1 infinite 20000ms;
}
.shape-group-14 .shape.shape-2 {
  left: 47%;
  bottom: 115px;
  -webkit-animation: movingleftright3 infinite 30000ms;
          animation: movingleftright3 infinite 30000ms;
}
.shape-group-14 .shape.shape-3 {
  right: 160px;
  bottom: 270px;
  -webkit-animation: movingleftright2 infinite 40000ms;
          animation: movingleftright2 infinite 40000ms;
}

.shape-group-16 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-16 .shape.shape-1 {
  top: 0;
  right: 0;
}
.shape-group-16 .shape.shape-2 {
  top: 120px;
  left: 30%;
}
.shape-group-16 .shape.shape-3 {
  bottom: 250px;
  left: 0;
}

.shape-group-17 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-17 .shape.shape-1 {
  top: 0;
  left: 0;
}
.shape-group-17 .shape.shape-2 {
  top: 360px;
  right: 0;
}
.shape-group-17 .shape.shape-3 {
  top: 430px;
  left: 0;
}
.shape-group-17 .shape.shape-4 {
  top: 35%;
  right: 0;
}
.shape-group-17 .shape.shape-5 {
  top: 56%;
  left: 0;
}
.shape-group-17 .shape.shape-6 {
  top: 75%;
  right: 0;
}

.shape-group-18 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-18 .shape.shape-1 {
  top: 0;
  right: 0;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-18 .shape.shape-1 img {
    width: 70%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-18 .shape.shape-1 img {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .shape-group-18 .shape.shape-1 img {
    width: 40%;
  }
}
.shape-group-18 .shape.shape-2 {
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-18 .shape.shape-2 img {
    width: 70%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-18 .shape.shape-2 img {
    width: 50%;
  }
}
.shape-group-18 .shape.shape-3 {
  top: 34%;
  left: 50%;
  -webkit-animation: movingleftright1 infinite 20000ms;
          animation: movingleftright1 infinite 20000ms;
}
.shape-group-18 .shape.shape-3 img {
  opacity: 0.5;
}
.shape-group-18 .shape.shape-4 {
  bottom: 240px;
  left: 45%;
  -webkit-animation: movingleftright2 infinite 30000ms;
          animation: movingleftright2 infinite 30000ms;
}
.shape-group-18 .shape.shape-4 img {
  width: 75px;
  opacity: 0.5;
}
.shape-group-18 .shape.shape-5 {
  bottom: 120px;
  left: 53%;
  -webkit-animation: movingleftright1 infinite 25000ms;
          animation: movingleftright1 infinite 25000ms;
}
.shape-group-18 .shape.shape-6 {
  bottom: 100px;
  left: 40%;
  -webkit-animation: movingleftright1 infinite 35000ms;
          animation: movingleftright1 infinite 35000ms;
}
.shape-group-18 .shape.shape-7 {
  top: 0;
  right: 100px;
  z-index: -2;
  text-align: right;
}
@media only screen and (max-width: 1199px) {
  .shape-group-18 .shape.shape-7 img {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .shape-group-18 .shape.shape-7 img {
    width: 60%;
  }
}

.shape-group-19 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-19 .shape.shape-1 {
  top: 0;
  right: 0;
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .shape-group-19 .shape.shape-1 img {
    width: 60%;
  }
}
.shape-group-19 .shape.shape-2 {
  top: 145px;
  right: 0;
}

.shape-group-20 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-20 .shape.shape-1 {
  top: 0;
  left: 120px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-20 .shape.shape-1 {
    left: -10px;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-20 .shape.shape-1 {
    left: -10px;
  }
}
@media only screen and (max-width: 991px) {
  .shape-group-20 .shape.shape-1 img {
    width: 60%;
  }
}
.shape-group-20 .shape.shape-2 {
  bottom: 0;
  right: 0;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-20 .shape.shape-2 img {
    width: 80%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-20 .shape.shape-2 img {
    width: 75%;
  }
}
@media only screen and (max-width: 991px) {
  .shape-group-20 .shape.shape-2 {
    display: none;
  }
}
.shape-group-20 .shape.shape-3 {
  right: -10px;
  bottom: 62px;
  z-index: 2;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-20 .shape.shape-3 {
    bottom: 220px;
  }
  .shape-group-20 .shape.shape-3 img {
    width: 60%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-20 .shape.shape-3 {
    bottom: 100px;
  }
  .shape-group-20 .shape.shape-3 img {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .shape-group-20 .shape.shape-3 img {
    width: 40%;
  }
}
.shape-group-20 .shape.shape-4 {
  left: 220px;
  top: 45%;
  height: 48px;
  width: 48px;
  -webkit-animation: movingleftright1 infinite 25000ms;
          animation: movingleftright1 infinite 25000ms;
}
.shape-group-20 .shape.shape-5 {
  left: 45%;
  bottom: 130px;
  height: 32px;
  width: 32px;
  -webkit-animation: movingleftright3 infinite 20000ms;
          animation: movingleftright3 infinite 20000ms;
}
.shape-group-20 .shape.shape-6 {
  left: 50%;
  top: 250px;
}
@media only screen and (max-width: 991px) {
  .shape-group-20 .shape.shape-6 {
    display: none;
  }
}
.shape-group-20 .shape.shape-7 {
  left: 65%;
  top: 180px;
  -webkit-animation: movingleftright3 infinite 20000ms;
          animation: movingleftright3 infinite 20000ms;
}
.shape-group-20 .shape.shape-8 {
  bottom: 0;
  right: 0;
}
@media only screen and (max-width: 991px) {
  .shape-group-20 .shape.shape-8 {
    display: none;
  }
}
.shape-group-20 .shape.shape-8.marque-images {
  background-repeat: repeat-x;
  background-size: auto;
  height: 250px;
  width: 52%;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-20 .shape.shape-8.marque-images {
    width: 49%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-20 .shape.shape-8.marque-images {
    width: 62%;
  }
}

.shape-group-21 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-21 .shape.shape-1 {
  top: 0;
  left: 0;
}
@media only screen and (max-width: 991px) {
  .shape-group-21 .shape.shape-1 img {
    width: 60%;
  }
}
.shape-group-21 .shape.shape-2 {
  top: 200px;
  right: 200px;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-21 .shape.shape-2 {
    top: 120px;
    right: 15px;
  }
  .shape-group-21 .shape.shape-2 img {
    width: 90%;
  }
}
@media only screen and (max-width: 1199px) {
  .shape-group-21 .shape.shape-2 {
    right: 15px;
  }
  .shape-group-21 .shape.shape-2 img {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .shape-group-21 .shape.shape-2 {
    display: none;
  }
}
.shape-group-21 .shape.shape-3 {
  right: 100px;
  top: 45%;
  height: 53px;
  width: 53px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .shape-group-21 .shape.shape-3 {
    display: none;
  }
}
.shape-group-21 .shape.shape-4 {
  left: 220px;
  top: 45%;
  height: 20px;
  width: 20px;
  -webkit-animation: movingleftright1 infinite 25000ms;
          animation: movingleftright1 infinite 25000ms;
}
.shape-group-21 .shape.shape-5 {
  left: 45%;
  bottom: 130px;
  height: 20px;
  width: 20px;
  -webkit-animation: movingleftright3 infinite 20000ms;
          animation: movingleftright3 infinite 20000ms;
}
.shape-group-21 .shape.shape-6 {
  right: 230px;
  bottom: 40%;
  height: 20px;
  width: 20px;
}
@media only screen and (max-width: 1199px) {
  .shape-group-21 .shape.shape-6 {
    display: none;
  }
}
.shape-group-21 .shape.shape-7 {
  right: 250px;
  top: 40%;
  height: 20px;
  width: 20px;
}
@media only screen and (max-width: 1199px) {
  .shape-group-21 .shape.shape-7 {
    display: none;
  }
}

@-webkit-keyframes movingleftright1 {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40% {
    -webkit-transform: translateX(50px) translateY(-200px);
    transform: translateX(50px) translateY(-200px);
  }
  75% {
    -webkit-transform: translateX(800px);
    transform: translateX(800px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}

@keyframes movingleftright1 {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40% {
    -webkit-transform: translateX(50px) translateY(-200px);
    transform: translateX(50px) translateY(-200px);
  }
  75% {
    -webkit-transform: translateX(800px);
    transform: translateX(800px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@-webkit-keyframes movingleftright2 {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40% {
    -webkit-transform: translateX(-50px) translateY(200px);
    transform: translateX(-50px) translateY(200px);
  }
  75% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@keyframes movingleftright2 {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40% {
    -webkit-transform: translateX(-50px) translateY(200px);
    transform: translateX(-50px) translateY(200px);
  }
  75% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@-webkit-keyframes movingleftright3 {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40% {
    -webkit-transform: translateX(100px) translateY(-200px);
    transform: translateX(100px) translateY(-200px);
  }
  75% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@keyframes movingleftright3 {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40% {
    -webkit-transform: translateX(100px) translateY(-200px);
    transform: translateX(100px) translateY(-200px);
  }
  75% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
.my_switcher {
  position: fixed;
  top: 200px;
  left: 0;
  background-color: #ff4338;
  background-color: var(--color-primary);
  border-radius: 0 30px 30px 0;
  padding: 10px;
  z-index: 5;
}
@media only screen and (max-width: 991px) {
  .my_switcher {
    position: static;
    position: initial;
    display: none;
  }
}
.my_switcher button {
  background-color: transparent;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
}
.my_switcher button span {
  padding: 0;
  text-align: center;
  color: #ffffff;
  color: var(--color-white);
  background-color: transparent;
  font-size: 18px;
  height: 40px;
  width: 40px;
  line-height: 36px;
  border: 1px solid transparent;
  border-radius: 50%;
}
@media only screen and (max-width: 767px) {
  .my_switcher button span {
    font-size: 16px;
    height: 30px;
    width: 30px;
  }
}
.my_switcher button span.dark {
  display: none;
}

body.active-dark-mode .my_switcher button span.light {
  display: none;
}
body.active-dark-mode .my_switcher button span.dark {
  display: block;
}

.main-wrapper {
  overflow: hidden;
  background-color: #ffffff;
  background-color: var(--color-white);
}

/*=======================================================================
3. Template Styles
=========================================================================*/
/*-------------------------
   About Us
-------------------------*/
.contact-form-box {
  background-color: #ffffff;
  background-color: var(--color-white);
  padding: 50px;
  border-radius: 30px;
}
@media only screen and (max-width: 767px) {
  .contact-form-box {
    padding: 40px 30px;
  }
}
.contact-form-box .title {
  font-size: 40px;
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .contact-form-box .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 1199px) {
  .contact-form-box .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-form-box .title {
    font-size: 28px;
  }
}
.contact-form-box p {
  text-align: center;
  font-size: 18px;
}
.contact-form-box .form-group {
  padding-top: 15px;
}
.contact-form-box .form-group .axil-btn {
  width: 50vh;
}
@media only screen and (max-width: 767px) {
  .contact-form-box .form-group .axil-btn {
    width: 25vh;
  }
}
.contact-form-box .form-group .axil-btn::after {
  width: calc(100% + 50px);
}
.contact-form-box.shadow-box {
  box-shadow: 0 20px 48px 0 rgba(153, 161, 170, 0.25);
}
.contact-form-box .btn-submit {
  display: flex;
  justify-content: center;
}
.contact-form-box .text-area {
  height: 16vh;
}

/*-------------------------
    Banner
-------------------------*/
.banner {
  padding: 255px 0 100px;
  margin-bottom: 80px;
  position: relative;
  z-index: 2;
  min-height: 800px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner {
    padding: 220px 0 0;
  }
}
@media only screen and (max-width: 1199px) {
  .banner {
    padding: 200px 0 0;
    min-height: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .banner {
    padding: 160px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .banner {
    padding: 150px 0 0;
  }
}
@media only screen and (max-width: 991px) {
  .banner .banner-content {
    margin-bottom: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .banner .banner-content {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .banner .banner-content {
    margin-bottom: 40px;
  }
}
.banner .banner-content .title {
  font-size: 80px;
  font-weight: 700;
  color: #27272E;
  color: var(--color-dark);
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner .banner-content .title {
    font-size: 68px;
  }
}
@media only screen and (max-width: 1199px) {
  .banner .banner-content .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .banner .banner-content .title {
    font-size: 55px;
  }
}
@media only screen and (max-width: 767px) {
  .banner .banner-content .title {
    font-size: 40px;
  }
}
.banner .banner-content .subtitle {
  font-size: 18px;
  font-size: var(--font-body-1);
  margin-bottom: 40px;
  width: 90%;
}
.banner .banner-content .axil-btn {
  padding: 28px 45px;
  box-shadow: 0 44px 84px -24px #5956e9;
}
@media only screen and (max-width: 991px) {
  .banner .banner-content .axil-btn {
    padding: 20px 35px;
  }
}
.banner .banner-thumbnail {
  position: relative;
}
.banner.banner-style-1 .banner-content {
  margin-top: 60px;
}
@media only screen and (max-width: 1199px) {
  .banner.banner-style-1 .banner-content {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 991px) {
  .banner.banner-style-1 .banner-content {
    margin-top: 0;
    padding-bottom: 0;
  }
}
.banner.banner-style-1 .banner-content .title {
  font-size: 78px;
  line-height: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner.banner-style-1 .banner-content .title {
    font-size: 68px;
  }
}
@media only screen and (max-width: 1199px) {
  .banner.banner-style-1 .banner-content .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .banner.banner-style-1 .banner-content .title {
    font-size: 55px;
  }
}
@media only screen and (max-width: 767px) {
  .banner.banner-style-1 .banner-content .title {
    font-size: 40px;
  }
}
.banner.banner-style-1 .banner-thumbnail {
  z-index: 5;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner.banner-style-1 .banner-thumbnail .large-thumb img {
    width: 74%;
  }
}
.banner.banner-style-1 .banner-thumbnail .large-thumb-2 {
  text-align: right;
  margin-top: -260px;
  margin-right: -50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner.banner-style-1 .banner-thumbnail .large-thumb-2 img {
    width: 85%;
  }
}
@media only screen and (max-width: 1199px) {
  .banner.banner-style-1 .banner-thumbnail .large-thumb-2 {
    margin-top: -200px;
    margin-right: -30px;
  }
  .banner.banner-style-1 .banner-thumbnail .large-thumb-2 img {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .banner.banner-style-1 .banner-thumbnail .large-thumb-2 {
    margin-top: -260px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .banner.banner-style-1 .banner-thumbnail .large-thumb-2 {
    margin-top: 0;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
}
.banner.banner-style-1 .banner-thumbnail .shape-group .shape {
  position: absolute;
}
.banner.banner-style-1 .banner-thumbnail .shape-group .shape.shape-1 {
  top: -25px;
  right: 20px;
  left: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner.banner-style-1 .banner-thumbnail .shape-group .shape.shape-1 {
    right: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .banner.banner-style-1 .banner-thumbnail .shape-group .shape.shape-1 {
    display: none;
  }
}

/*-------------------------
   Brand
-------------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

video {
  object-fit: cover;
  width: 100%;
  height: 80vh;
}

.text-on-video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-on-video .title {
  color: #ffffff;
  color: var(--color-white);
}
.text-on-video p {
  color: #ffffff;
  color: var(--color-white);
}

/*-------------------------
   Case Study
-------------------------*/
.case-study-featured {
  margin-bottom: 60px;
}
.case-study-featured .section-heading .title {
  letter-spacing: -0.045em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 5px;
}
@media only screen and (max-width: 575px) {
  .case-study-featured .section-heading .title {
    -webkit-line-clamp: 2;
  }
}
.case-study-featured .section-heading p {
  width: 100%;
  font-size: 16px;
}

.case-study-counterup {
  display: flex;
  align-items: center;
}
.case-study-counterup .single-counterup {
  margin-right: 60px;
  padding-right: 60px;
  border-right: 1px solid #C7C7D5;
  border-right: 1px solid var(--color-ghost);
}
.case-study-counterup .single-counterup:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
@media only screen and (max-width: 1199px) {
  .case-study-counterup .single-counterup {
    margin-right: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .case-study-counterup .single-counterup {
    margin-right: 30px;
    padding-right: 30px;
  }
}
.case-study-counterup .count-number {
  color: #C75C6F;
  color: var(--color-rose);
  letter-spacing: -0.045em;
  margin-bottom: 0;
  padding: 0 6px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .case-study-counterup .count-number {
    padding: 0 2px;
  }
}
@media only screen and (max-width: 1199px) {
  .case-study-counterup .count-number {
    padding: 0;
  }
}
.case-study-counterup .count-number span {
  vertical-align: middle;
}

.case-study-featured-thumb {
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .case-study-featured-thumb {
    text-align: left;
    margin-bottom: 30px;
  }
}
.case-study-featured-thumb img {
  display: inline-block;
}

.case-study-page-area .case-study-featured-thumb {
  margin-bottom: 150px;
}
@media only screen and (max-width: 991px) {
  .case-study-page-area .case-study-featured-thumb {
    margin-bottom: 60px;
  }
}

.case-study-solution {
  position: relative;
  z-index: 1;
}
.case-study-solution .section-heading p {
  width: 100%;
}

/*-------------------------
   plans
-------------------------*/
.plans-table {
  background-color: var(--color-whit e);
  padding: 40px;
  border-radius: 30px;
  position: relative;
  margin-bottom: 30px;
  height: 450px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .plans-table {
    padding: 40px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .plans-table {
    padding: 40px 30px;
  }
}
.plans-table .plans-header {
  text-align: center;
  border-bottom: 1px solid rgb(227, 230, 233);
}
.plans-table .plans-header .title {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 8px;
  letter-spacing: -0.045em;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 1199px) {
  .plans-table .plans-header .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .plans-table .plans-header .title {
    font-size: 30px;
  }
}
.plans-table .plans-header .subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #ff4338;
  color: var(--color-primary);
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  margin-bottom: 20px;
}
.plans-table .plans-body {
  margin-top: 30px;
}
.plans-table .plans-body li {
  padding: 12px 0;
  color: #999FAE;
  color: var(--color-gray-2);
  padding-left: 34px;
  position: relative;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.plans-table:hover, .plans-table.active {
  background-color: #ff4338;
  background-color: var(--color-primary);
}
.plans-table:hover .plans-header .title, .plans-table.active .plans-header .title {
  color: #ffffff;
  color: var(--color-white);
}
.plans-table:hover .plans-header .subtitle, .plans-table.active .plans-header .subtitle {
  color: #ffffff;
  color: var(--color-white);
}
.plans-table:hover .plans-body li, .plans-table.active .plans-body li {
  color: #ffffff;
  color: var(--color-white);
}
.plans-table.plans-borderd {
  border: 1px solid #E3E6E9;
  border: var(--border-light);
}

/*-------------------------
   Project
-------------------------*/
@media only screen and (max-width: 991px) {
  .carousel-control-next {
    top: 20vh;
  }
}

@media only screen and (max-width: 991px) {
  .carousel-control-prev {
    top: 20vh;
  }
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 8 8'><path d='M2.75 0l-1 1 2 2-2 2 1 1 3-3-3-3z'/></svg>");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-3 3 3 3 1-1-2-2 2-2-1-1z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  visibility: hidden;
}

.project-table {
  padding: 30px 0 40px;
  border-radius: 30px;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-table {
    padding: 40px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .project-table {
    padding: 40px 10px;
  }
}
.project-table .project-header {
  text-align: center;
  border-bottom: 1px solid rgb(227, 230, 233);
}
.project-table .project-header .title {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
  letter-spacing: -0.045em;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 1199px) {
  .project-table .project-header .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .project-table .project-header .title {
    font-size: 30px;
  }
}
.project-table .project-body {
  margin-top: 30px;
}
.project-table .project-body li {
  padding: 12px 0;
  color: #999FAE;
  color: var(--color-gray-2);
  padding-left: 34px;
  position: relative;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 479px) {
  .project-table .project-body li {
    padding-left: 14px;
  }
}
.project-table:hover, .project-table.active {
  background-color: #ff4338;
  background-color: var(--color-primary);
}
.project-table:hover .project-header .title, .project-table.active .project-header .title {
  color: #ffffff;
  color: var(--color-white);
}
.project-table:hover .project-body li, .project-table.active .project-body li {
  color: #ffffff;
  color: var(--color-white);
}
.project-table.project-borderd {
  border: 1px solid #E3E6E9;
  border: var(--border-light);
}

.carousel-inner {
  width: 75%;
  margin: 0 auto;
}

.test-class {
  text-align: center;
  margin: auto;
}
.test-class h2 {
  font-size: 100px;
}

/*-------------------------
    Service
-------------------------*/
.services-grid {
  padding: 35px 40px 35px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  text-align: center;
}
.services-grid .thumbnail {
  max-width: 100%;
  border-radius: 20px;
}
.services-grid .thumbnail img {
  height: 30vh;
}
.services-grid .thumbnail1 {
  background-color: #5856D6;
  background-color: var(--color-design);
}
.services-grid .thumbnail2 {
  background-color: #33CCFF;
  background-color: var(--color-mobile);
}
.services-grid .thumbnail3 {
  background-color: #f79c47;
  background-color: var(--color-web-dev);
}
.services-grid .thumbnail4 {
  background-color: #34C759;
  background-color: var(--color-devOps);
}
.services-grid .content {
  flex: 1 1;
}
.services-grid .content .title {
  padding-top: 40px;
  margin-bottom: 10px;
}
.services-grid .content p {
  color: #000000;
  color: var(--color-black);
  opacity: 0.5;
  letter-spacing: -0.025em;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  margin: 0 0 30px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .services-grid .content .sub-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 991px) {
  .services-grid .content .sub-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.services-grid .content .sub-title span {
  border-radius: 12px;
  margin: 5px;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  font-size: var(--font-body-4);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .services-grid .content .sub-title span {
    display: block;
    width: 70%;
  }
}
@media only screen and (max-width: 991px) {
  .services-grid .content .sub-title span {
    width: 70%;
  }
}
.services-grid .content .sub-title1 span {
  color: #5856D6;
  color: var(--color-design);
  background-color: #ffffff;
  background-color: var(--color-white);
  border: 1px solid #5856D6;
  border: 1px solid var(--color-design);
}
.services-grid .content .sub-title2 span {
  color: #33CCFF;
  color: var(--color-mobile);
  background-color: #ffffff;
  background-color: var(--color-white);
  border: 1px solid #33CCFF;
  border: 1px solid var(--color-mobile);
}
.services-grid .content .sub-title3 span {
  color: #f79c47;
  color: var(--color-web-dev);
  background-color: #ffffff;
  background-color: var(--color-white);
  border: 1px solid #f79c47;
  border: 1px solid var(--color-web-dev);
}
.services-grid .content .sub-title4 span {
  color: #34C759;
  color: var(--color-devOps);
  background-color: #ffffff;
  background-color: var(--color-white);
  border: 1px solid #34C759;
  border: 1px solid var(--color-devOps);
}
.services-grid:hover:after, .services-grid.active:after {
  visibility: visible;
  opacity: 1;
}
.services-grid:hover .thumbnail::after, .services-grid.active .thumbnail::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.services-grid:hover .content .sub-title1 span, .services-grid.active .content .sub-title1 span {
  color: #ffffff;
  color: var(--color-white);
  background-color: #5856D6;
  background-color: var(--color-design);
  border: 1px solid #ECF2F6;
  border: var(--border-lighter);
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.services-grid:hover .content .sub-title2 span, .services-grid.active .content .sub-title2 span {
  color: #ffffff;
  color: var(--color-white);
  background-color: #33CCFF;
  background-color: var(--color-mobile);
  border: 1px solid #ECF2F6;
  border: var(--border-lighter);
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.services-grid:hover .content .sub-title3 span, .services-grid.active .content .sub-title3 span {
  color: #ffffff;
  color: var(--color-white);
  background-color: #f79c47;
  background-color: var(--color-web-dev);
  border: 1px solid #ECF2F6;
  border: var(--border-lighter);
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.services-grid:hover .content .sub-title4 span, .services-grid.active .content .sub-title4 span {
  color: #ffffff;
  color: var(--color-white);
  background-color: #34C759;
  background-color: var(--color-devOps);
  border: 1px solid #ECF2F6;
  border: var(--border-lighter);
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}

/*-------------------------
   Testimonial
-------------------------*/
.testimonial-grid {
  padding: 50px 30px 30px;
  border-bottom: 2px solid #ff4338;
  border-bottom: 2px solid var(--color-primary);
  text-align: center;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 767px) {
  .testimonial-grid {
    padding: 20px 0;
  }
}
.testimonial-grid .social-media {
  font-size: 20px;
  font-weight: 700;
  color: #C75C6F;
  color: var(--color-rose);
  font-family: "DM Sans", sans-serif;
  font-family: var(--font-primary);
  letter-spacing: -0.025em;
  margin-bottom: 30px;
}
.testimonial-grid p {
  font-size: 24px;
  line-height: 1.2;
  color: #292930;
  color: var(--color-text-dark);
  font-family: "DM Sans", sans-serif;
  font-family: var(--font-primary);
  letter-spacing: -0.025em;
  width: 500px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .testimonial-grid p {
    font-size: 18px;
    width: 250px;
  }
}
.testimonial-grid .author-info {
  display: flex;
  align-items: center;
}
.testimonial-grid .author-info .thumb img {
  border-radius: 50%;
}
.testimonial-grid .author-info .content {
  flex: 1 1;
}
.testimonial-grid .author-info .content span {
  display: block;
}
.testimonial-grid .author-info .content .name {
  font-weight: 500;
  color: #292930;
  color: var(--color-text-dark);
  letter-spacing: -0.04em;
  margin-bottom: 3px;
}
.testimonial-grid .author-info .content .designation {
  font-size: 14px;
  color: #999FAE;
  color: var(--color-gray-2);
  letter-spacing: -0.025em;
}
.testimonial-grid:hover, .testimonial-grid.active {
  border-top-color: #ff4338;
  border-top-color: var(--color-primary);
}
.testimonial-grid.testimonial-light p {
  color: #ffffff;
  color: var(--color-white);
}
.testimonial-grid.testimonial-light .author-info .content .name {
  color: #ffffff;
  color: var(--color-white);
}

.customer-review-area .section-heading .title {
  font-size: 50px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1199px) {
  .customer-review-area .section-heading .title {
    font-size: 46px;
  }
}
@media only screen and (max-width: 991px) {
  .customer-review-area .section-heading .title {
    font-size: 38px;
  }
}
.customer-review-area .review-site-logo {
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .customer-review-area .review-site-logo img {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .customer-review-area .review-site-logo {
    text-align: left;
    margin-bottom: 5px;
  }
}

.followers-list {
  display: flex;
  align-items: center;
  margin: 90px -50px -15px;
}
@media only screen and (max-width: 991px) {
  .followers-list {
    margin: 90px -15px -15px;
  }
}
@media only screen and (max-width: 767px) {
  .followers-list {
    margin: 50px -15px -15px;
  }
}
.followers-list li {
  margin: 0 50px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 991px) {
  .followers-list li {
    margin: 0 15px;
  }
}
.followers-list li a i, .followers-list li a .icon {
  height: 67px;
  width: 67px;
  line-height: 67px;
  text-align: center;
  color: #ffffff;
  color: var(--color-white);
  border-radius: 100%;
  margin-right: 25px;
}
@media only screen and (max-width: 991px) {
  .followers-list li a i, .followers-list li a .icon {
    margin-right: 10px;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
.followers-list li a span {
  color: #000248;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  font-family: "DM Sans", sans-serif;
  font-family: var(--font-primary);
}
@media only screen and (max-width: 991px) {
  .followers-list li a span {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .followers-list li a span {
    margin-top: 10px;
  }
}
.followers-list li.dribble a i, .followers-list li.dribble a .icon {
  background-color: #EA4C89;
  box-shadow: 0 10px 37px -5px rgba(234, 76, 137, 0.5);
}
.followers-list li.behance a i, .followers-list li.behance a .icon {
  background-color: #0067FF;
  box-shadow: 0 10px 37px -5px rgba(0, 103, 255, 0.5);
}
.followers-list li.linkedin a i, .followers-list li.linkedin a .icon {
  background-color: #0177AC;
  box-shadow: 0 10px 37px -5px rgba(1, 119, 172, 0.5);
}

/*-------------------------
    404 Error
-------------------------*/
.onepage-screen-area {
  position: relative;
  z-index: 1;
  background: linear-gradient(90deg, #ECF2F6 0%, rgba(236, 242, 246, 0) 70.31%);
  background: var(--gradient-primary);
  min-height: 500px;
  height: 100vh;
  padding: 150px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .onepage-screen-area {
    padding: 150px 0 50px;
  }
}
@media only screen and (max-width: 1199px) {
  .onepage-screen-area {
    padding: 150px 0 50px;
  }
}
@media only screen and (max-width: 991px) {
  .onepage-screen-area {
    height: 100%;
    padding: 200px 0 100px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .onepage-screen-area {
    padding: 150px 0 100px;
  }
}
.onepage-screen-area .content .title {
  font-size: 80px;
  margin-bottom: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .onepage-screen-area .content .title {
    font-size: 70px;
  }
}
@media only screen and (max-width: 1199px) {
  .onepage-screen-area .content .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .onepage-screen-area .content .title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .onepage-screen-area .content .title {
    font-size: 40px;
  }
}
.onepage-screen-area .content .axil-btn {
  padding: 22px 80px;
}
@media only screen and (max-width: 991px) {
  .onepage-screen-area .content .axil-btn {
    padding: 18px 50px;
  }
}
.onepage-screen-area .content .countdown {
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 40px;
}
.onepage-screen-area .content .countdown .countdown-section {
  flex: 1 1;
  text-align: center;
  position: relative;
}
.onepage-screen-area .content .countdown .countdown-section::after {
  content: "";
  height: 80px;
  width: 1px;
  background-color: #ECF2F6;
  background-color: var(--color-light);
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.onepage-screen-area .content .countdown .countdown-section:last-child::after {
  display: none;
}
.onepage-screen-area .content .countdown .countdown-section .countdown-number {
  font-size: 80px;
  font-weight: 700;
  color: #C75C6F;
  color: var(--color-rose);
  font-family: "DM Sans", sans-serif;
  font-family: var(--font-primary);
  line-height: 1;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .onepage-screen-area .content .countdown .countdown-section .countdown-number {
    font-size: 70px;
  }
}
@media only screen and (max-width: 1199px) {
  .onepage-screen-area .content .countdown .countdown-section .countdown-number {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .onepage-screen-area .content .countdown .countdown-section .countdown-number {
    font-size: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .onepage-screen-area .content .countdown .countdown-section .countdown-number {
    font-size: 30px;
  }
}
.onepage-screen-area .content .countdown .countdown-section .countdown-unit {
  font-size: 18px;
  display: block;
}
@media only screen and (max-width: 575px) {
  .onepage-screen-area .content .countdown .countdown-section .countdown-unit {
    font-size: 15px;
  }
}
.onepage-screen-area .thumbnail {
  margin-right: -90px;
}
@media only screen and (max-width: 991px) {
  .onepage-screen-area .thumbnail {
    margin-right: 0;
  }
}
.onepage-screen-area .shape-group-8 .shape-3 {
  top: auto;
  bottom: -80px;
}
.onepage-screen-area.coming-soon-area {
  margin-top: 0;
  padding: 50px 0;
}
@media only screen and (max-width: 1199px) {
  .onepage-screen-area.coming-soon-area {
    height: 100%;
    text-align: center;
  }
}
.onepage-screen-area.coming-soon-area .site-logo {
  margin-bottom: 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .onepage-screen-area.coming-soon-area .site-logo {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1199px) {
  .onepage-screen-area.coming-soon-area .site-logo {
    margin-bottom: 50px;
  }
}
.onepage-screen-area.coming-soon-area .site-logo .logo-dark {
  display: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .onepage-screen-area.coming-soon-area .thumbnail {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .onepage-screen-area.coming-soon-area .thumbnail {
    display: none;
  }
}
.onepage-screen-area.coming-soon-area .shape-group-8 .shape-3 {
  bottom: auto;
  top: 25px;
}

/*-------------------------
    Privacy Policy
-------------------------*/
.privacy-policy-area .section-title {
  margin-bottom: 60px;
}
.privacy-policy-area .section-title .title {
  border-bottom: 3px solid #ff4338;
  border-bottom: 3px solid var(--color-primary);
  padding-bottom: 15px;
}
.privacy-policy-area h4 {
  margin-bottom: 20px;
}
.privacy-policy-area p {
  line-height: 1.7;
}
.privacy-policy-area ul {
  padding-left: 20px;
  margin-bottom: 40px;
}
.privacy-policy-area ul li {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 28px;
  transition: all 0.3s ease-in-out;
}
.privacy-policy-area ul li:hover {
  color: #ff4338;
  color: var(--color-primary);
}

/*-------------------------
   Project
-------------------------*/
@media only screen and (max-width: 991px) {
  .carousel-control-next {
    top: 20vh;
  }
}

@media only screen and (max-width: 991px) {
  .carousel-control-prev {
    top: 20vh;
  }
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 8 8'><path d='M2.75 0l-1 1 2 2-2 2 1 1 3-3-3-3z'/></svg>");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-3 3 3 3 1-1-2-2 2-2-1-1z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  visibility: hidden;
}

.project-table {
  padding: 30px 0 40px;
  border-radius: 30px;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-table {
    padding: 40px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .project-table {
    padding: 40px 10px;
  }
}
.project-table .project-header {
  text-align: center;
  border-bottom: 1px solid rgb(227, 230, 233);
}
.project-table .project-header .title {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
  letter-spacing: -0.045em;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 1199px) {
  .project-table .project-header .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .project-table .project-header .title {
    font-size: 30px;
  }
}
.project-table .project-body {
  margin-top: 30px;
}
.project-table .project-body li {
  padding: 12px 0;
  color: #999FAE;
  color: var(--color-gray-2);
  padding-left: 34px;
  position: relative;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 479px) {
  .project-table .project-body li {
    padding-left: 14px;
  }
}
.project-table:hover, .project-table.active {
  background-color: #ff4338;
  background-color: var(--color-primary);
}
.project-table:hover .project-header .title, .project-table.active .project-header .title {
  color: #ffffff;
  color: var(--color-white);
}
.project-table:hover .project-body li, .project-table.active .project-body li {
  color: #ffffff;
  color: var(--color-white);
}
.project-table.project-borderd {
  border: 1px solid #E3E6E9;
  border: var(--border-light);
}

.carousel-inner {
  width: 75%;
  margin: 0 auto;
}

.test-class {
  text-align: center;
  margin: auto;
}
.test-class h2 {
  font-size: 100px;
}

/*=======================================================================
4.Blocks Styles 
=========================================================================*/
/*-------------------------
   Header Styles
-------------------------*/
.axil-header {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 4;
}
.axil-header .header-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.axil-header .header-navbar .header-logo a {
  display: block;
}
.axil-header .header-navbar .header-logo .dark-version-logo {
  display: none;
}
.axil-header .header-navbar .header-logo .sticky-logo {
  display: none;
}
.axil-header .header-navbar .header-logo img {
  width: 190px;
}
@media only screen and (max-width: 991px) {
  .axil-header .header-navbar .header-logo img {
    width: 150px;
  }
}
.axil-header .header-navbar .header-main-nav {
  flex: 1 1;
  margin: 0 55px 0 50px;
}
@media only screen and (max-width: 1199px) {
  .axil-header .header-navbar .header-main-nav {
    margin: 0 30px;
  }
}
@media only screen and (max-width: 991px) {
  .axil-header .header-navbar .header-main-nav {
    margin: 0;
  }
}
.axil-header.header-style-1 .axil-mainmenu.axil-sticky .header-navbar .header-logo .light-version-logo {
  display: none;
}
.axil-header.header-style-1 .axil-mainmenu.axil-sticky .header-navbar .header-logo .sticky-logo {
  display: block;
}

.axil-mainmenu {
  padding: 15px 0;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .axil-mainmenu {
    padding: 35px 0;
  }
}
@media only screen and (max-width: 1199px) {
  .axil-mainmenu {
    padding: 30px 0;
  }
}
.axil-mainmenu.axil-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-color: #ffffff;
  background-color: var(--color-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  -webkit-animation: headerSlideDown 0.8s ease forwards;
          animation: headerSlideDown 0.8s ease forwards;
}
.axil-mainmenu.axil-sticky .header-action .sidemenu-btn .btn-wrap {
  background-color: #525260;
  background-color: var(--color-body);
}
.axil-mainmenu.axil-sticky .header-action .sidemenu-btn .btn-wrap span {
  background-color: #ffffff;
  background-color: var(--color-white);
}
.axil-mainmenu.axil-sticky .header-action .social-icon-list li a {
  color: #27272E;
  color: var(--color-dark);
}
.axil-mainmenu.axil-sticky .header-action .social-icon-list li a:hover {
  color: #ff4338;
  color: var(--color-primary);
}

.mainmenu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
}
.mainmenu > li {
  margin: 0 24px;
}
@media only screen and (max-width: 1199px) {
  .mainmenu > li {
    margin: 0 11px;
  }
}
.mainmenu > li > a {
  color: #525260;
  color: var(--color-body);
  font-weight: 500;
  font-size: 16px;
  font-size: var(--font-body-2);
  font-family: "DM Sans", sans-serif;
  font-family: var(--font-primary);
  line-height: 60px;
  height: 60px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.mainmenu > li > a::before {
  content: "";
  height: 2px;
  width: 0;
  background-color: #ff4338;
  background-color: var(--color-primary);
  position: absolute;
  bottom: 12px;
  left: 0;
  opacity: 0;
  transition: 0.5s;
}
.mainmenu > li > a:hover {
  color: #ff4338;
  color: var(--color-primary);
}
.mainmenu > li > a:hover::before {
  opacity: 1;
  width: 100%;
}
.mainmenu > li > a.active {
  color: #ff4338;
  color: var(--color-primary);
}
.mainmenu > li > a.active::before {
  width: 100%;
  opacity: 1;
}
.mainmenu > .menu-item-has-children {
  position: relative;
}
.mainmenu > .menu-item-has-children > a {
  position: relative;
}
.mainmenu > .menu-item-has-children > a svg {
  margin-left: 2px;
  font-size: 14px;
}
.mainmenu > .menu-item-has-children .axil-submenu {
  position: absolute;
  top: 100%;
  left: 20px;
  background: #ffffff;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  min-width: 250px;
  padding: 15px 10px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  list-style: none;
  margin: 0;
  box-shadow: 0 13px 48px 0 rgba(0, 0, 0, 0.15);
}
.mainmenu > .menu-item-has-children .axil-submenu li {
  margin: 0;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}
.mainmenu > .menu-item-has-children .axil-submenu li a {
  position: relative;
  font-size: 14px;
  text-transform: capitalize;
  color: #525260;
  color: var(--color-body);
  font-weight: 500;
  padding: 7px 15px;
  border-radius: 4px;
  display: block;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.mainmenu > .menu-item-has-children .axil-submenu li a::after {
  content: "";
  height: 1px;
  width: 0;
  background-color: #ff4338;
  background-color: var(--color-primary);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.mainmenu > .menu-item-has-children .axil-submenu li a:hover {
  color: #ff4338;
  color: var(--color-primary);
}
.mainmenu > .menu-item-has-children .axil-submenu li a:hover::after {
  width: 100%;
}
.mainmenu > .menu-item-has-children .axil-submenu li a.active {
  color: #ff4338;
  color: var(--color-primary);
}
.mainmenu > .menu-item-has-children .axil-submenu li a.active::after {
  width: 100%;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu {
  opacity: 1;
  visibility: visible;
  z-index: 9;
  left: 0;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(1n) {
  transition-delay: 0.1s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(2n) {
  transition-delay: 0.2s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(3n) {
  transition-delay: 0.3s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(4n) {
  transition-delay: 0.4s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(5n) {
  transition-delay: 0.5s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(6n) {
  transition-delay: 0.6s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(7n) {
  transition-delay: 0.7s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(8n) {
  transition-delay: 0.8s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(9n) {
  transition-delay: 0.8s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(10n) {
  transition-delay: 0.8s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(11n) {
  transition-delay: 0.8s;
}
.mainmenu > .menu-item-has-children:hover .axil-submenu li:nth-child(12n) {
  transition-delay: 0.8s;
}

.header-action > ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header-action > ul > li {
  margin-right: 30px;
}
.header-action > ul > li:last-child {
  margin-right: 0;
}
.header-action .sidemenu-btn {
  margin-right: 0;
}
.header-action .sidemenu-btn .btn-wrap {
  height: 60px;
  width: 60px;
  background-color: #ECF2F6;
  background-color: var(--color-light);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 991px) {
  .header-action .sidemenu-btn .btn-wrap {
    height: 50px;
    width: 50px;
  }
}
.header-action .sidemenu-btn .btn-wrap span {
  height: 2px;
  width: 20px;
  background-color: #27272E;
  background-color: var(--color-dark);
  margin-bottom: 4px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.header-action .sidemenu-btn .btn-wrap span:last-child {
  margin-bottom: 0;
  width: 10px;
  margin-right: -10px;
}
.header-action .sidemenu-btn .btn-wrap:focus {
  box-shadow: none;
}
.header-action .sidemenu-btn .btn-wrap:hover span:last-child {
  width: 20px;
  margin-right: 0;
}
.header-action .sidemenu-btn .btn-wrap:hover span:first-child {
  width: 10px;
  margin-right: -10px;
}
.header-action .sidemenu-btn .btn-wrap.btn-dark {
  background-color: #525260;
  background-color: var(--color-body);
}
.header-action .sidemenu-btn .btn-wrap.btn-dark span {
  background-color: #ffffff;
  background-color: var(--color-white);
}
@media only screen and (max-width: 575px) {
  .header-action .header-social-link {
    display: none;
  }
}
.header-action .social-icon-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -15px;
}
.header-action .social-icon-list li {
  margin: 15px;
}
.header-action .social-icon-list li a {
  font-size: 20px;
  color: #525260;
  color: var(--color-body);
}
@media only screen and (max-width: 991px) {
  .header-action .social-icon-list li a {
    color: #27272E;
    color: var(--color-dark);
  }
}
.header-action .social-icon-list li a:hover {
  color: #ff4338;
  color: var(--color-primary);
}
.header-action .header-btn {
  margin-right: 0;
}
@media only screen and (max-width: 1399px) {
  .header-action .header-btn .axil-btn {
    padding: 15px 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action .header-btn .axil-btn {
    padding: 15px 25px;
  }
}
@media only screen and (max-width: 991px) {
  .header-action .header-btn {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .header-action .header-btn {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .header-action .mobile-menu-btn {
    display: none;
  }
}
.header-action .mobile-menu-btn .btn-wrap {
  height: 50px;
  width: 50px;
}
.header-action .my_switcher {
  margin-left: 20px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 52px;
  padding: 0;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .header-action .my_switcher {
    margin-left: 15px;
  }
}
.header-action .my_switcher button {
  width: 100%;
  height: 100%;
  line-height: 1;
}
.header-action .my_switcher button span {
  width: 100%;
  line-height: 34px;
}
@media only screen and (max-width: 767px) {
  .header-action .my_switcher button span {
    line-height: 24px;
  }
}

.mobilemenu-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 10;
  transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  visibility: hidden;
  opacity: 0;
}
.mobilemenu-popup:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 300px;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.mobilemenu-popup .mobilemenu-inner {
  width: 300px;
  background-color: #ffffff;
  background-color: var(--color-white);
  padding: 0 10px 50px;
  overflow-y: auto;
  height: 100%;
}
.mobilemenu-popup .mainmenu-nav {
  display: block;
}
.mobilemenu-popup .mobilemenu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  padding: 20px 0;
  border-bottom: 1px solid #DBDEFF;
  border-bottom: 1px solid var(--color-fog);
}
@media only screen and (min-width: 992px) {
  .mobilemenu-popup .mobilemenu-header {
    display: none;
  }
}
.mobilemenu-popup .mobilemenu-header .mobile-nav-logo {
  width: 150px;
  line-height: 1;
}
.mobilemenu-popup .mobilemenu-header .mobile-nav-logo .dark-mode {
  display: none;
}
.mobilemenu-popup .mobilemenu-header .mobile-menu-close {
  background-color: transparent;
  height: 30px;
  width: 30px;
  border: 2px solid #99A1AA;
  border: 2px solid var(--color-gray-4);
  border-radius: 10px;
  color: #27272E;
  color: var(--color-dark);
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  line-height: 1;
  margin: 0;
  padding: 0;
}
.mobilemenu-popup .mobilemenu-header .mobile-menu-close:hover {
  background-color: #ff4338;
  background-color: var(--color-primary);
  border-color: #ff4338;
  border-color: var(--color-primary);
  color: #ffffff;
  color: var(--color-white);
}
.mobilemenu-popup .axil-mainmenu.axil-sticky {
  -webkit-animation: none;
          animation: none;
}
.mobilemenu-popup .mainmenu-nav .mainmenu {
  display: block;
  height: 100%;
  overflow: auto;
}
.mobilemenu-popup .mainmenu-nav .mainmenu > li {
  border-bottom: 1px solid #DBDEFF;
  border-bottom: 1px solid var(--color-fog);
}
.mobilemenu-popup .mainmenu-nav .mainmenu > li > a {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobilemenu-popup .mainmenu-nav .mainmenu > li > a::before {
  display: none;
}
.mobilemenu-popup .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu {
  display: none;
  position: static;
  position: initial;
  visibility: visible;
  opacity: 1;
  min-width: 100%;
  box-shadow: none;
  padding: 0 0 10px;
  transition: none 0s ease 0s;
  transition: initial;
}
.mobilemenu-popup .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu li {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.mobilemenu-popup .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu li a {
  padding: 7px 10px;
}
.mobilemenu-popup .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu li a::after {
  display: none;
}
.mobilemenu-popup .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu.active {
  display: block;
}
.mobilemenu-popup .mainmenu-nav .mainmenu .menu-item-has-children.menu-item-open .axil-submenu {
  display: block;
}
.mobilemenu-popup .mobilemenu-body {
  padding: 0;
}
.mobilemenu-popup.show {
  left: 0;
  visibility: visible;
  opacity: 1;
}
.mobilemenu-popup.show:after {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 991px) {
  .mainmenu-nav {
    display: none;
  }
}
@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -20px;
    opacity: 0;
  }
  30% {
    margin-top: -10px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes headerSlideDown {
  0% {
    margin-top: -20px;
    opacity: 0;
  }
  30% {
    margin-top: -10px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
/*-------------------------
    Case Studies Styles
-------------------------*/
.case-list {
  display: flex;
  align-items: center;
  border-top: 1px solid #E3E6E9;
  border-top: var(--border-light);
  border-bottom: 1px solid #E3E6E9;
  border-bottom: var(--border-light);
  padding: 40px 20px 40px 0;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 1199px) {
  .case-list {
    padding: 40px 0;
  }
}
@media only screen and (max-width: 767px) {
  .case-list {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .case-list .case-thumbnail {
    margin-bottom: 30px;
    text-align: center;
  }
}
.case-list .case-thumbnail a {
  border-radius: 30px;
  overflow: hidden;
  display: block;
}
.case-list .case-thumbnail a img {
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
@media only screen and (max-width: 767px) {
  .case-list .case-thumbnail a img {
    width: 100%;
  }
}
.case-list .case-content {
  flex: 1 1;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .case-list .case-content {
    margin-left: 0;
  }
}
.case-list .case-content .title {
  margin-bottom: 20px;
}
.case-list .case-content p {
  color: #757589;
  color: var(--color-gray-1);
  letter-spacing: -0.025em;
  margin-bottom: 10px;
}
.case-list .case-content p span {
  color: #292930;
  color: var(--color-text-dark);
  margin-right: 10px;
}
.case-list:hover, .case-list.active {
  border-top-color: #ff4338;
  border-top-color: var(--color-primary);
}
@media only screen and (max-width: 767px) {
  .info {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .info img {
    padding-bottom: 2vh;
  }
}
@media only screen and (max-width: 767px) {
  .responsible {
    text-align: center;
  }
}
.responsible span {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-right: 5px;
}
@media only screen and (max-width: 767px) {
  .responsible span {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .challenge {
    text-align: center;
  }
}

.screen-slider h4 {
  padding-top: 12vh;
}
.screen-slider img {
  margin: 0 auto;
  height: 60%;
  width: 60%;
}

.app-features {
  background-color: #0ba55b;
}
@media only screen and (max-width: 767px) {
  .app-features {
    text-align: center;
  }
}
.app-features .cont h4 {
  color: #fff;
  margin: 0;
}
.app-features .feature-item {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-top: 6vh;
  max-width: 85%;
  padding: 5vh 3vh;
}
.app-features .feature-item h5 {
  text-align: left;
  color: #0ba55b;
}
.app-features .feature-item p {
  text-align: left;
}

.lampa {
  background-color: #5FB2EE;
}
.lampa .feature-item h5 {
  color: #5FB2EE;
}

.EnoughTea {
  background-color: #2e9bff;
}
.EnoughTea .feature-item h5 {
  color: #2e9bff;
}

.testimoial {
  height: 80vh;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
}
.testimoial .container .sub-container {
  background-color: #fff;
  border-radius: 30px;
}
@media only screen and (max-width: 767px) {
  .testimoial .container .sub-container {
    margin-top: 5vh;
  }
}
.testimoial .container .sub-container h5 {
  padding: 5vh 0 1vh;
}
@media only screen and (max-width: 767px) {
  .testimoial .container .sub-container h5 {
    padding: 5vh 0 5vh;
    margin: 0;
  }
}
.testimoial .container .sub-container img {
  margin: 0 auto;
}
.testimoial .container .sub-container span {
  padding-top: 1vh;
  font-size: 0.8em;
}
.testimoial .container .sub-container .role {
  display: block;
}
.testimoial .container .sub-container p {
  padding: 5vh;
}

.test {
  width: 35%;
}

.carousel-control-prev-icon-test {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2376797b'  viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-next-icon-test {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2376797b' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

@media only screen and (max-width: 991px) {
  .carousel-control-prev,
.carousel-control-next {
    top: 0;
  }
}
/*-------------------------
   Footer Styles
-------------------------*/
.footer-area {
  padding-top: 10px;
}

.footer-top {
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .footer-top {
    padding-bottom: 50px;
  }
}

.footer-social-link {
  margin-top: 15px;
}
.footer-social-link ul {
  display: flex;
  align-items: center;
  margin: 0 -5px;
}
.footer-social-link ul li {
  padding-right: 30px;
}
.footer-social-link ul li a {
  color: #999AB8;
  color: var(--color-gray-3);
  font-size: 30px;
  padding: 5px;
}
@media only screen and (max-width: 991px) {
  .footer-social-link ul li a {
    font-size: 20px;
  }
}
.footer-social-link ul li a:hover {
  color: #f82e23;
  color: var(--color-link);
}

.footer-widget {
  margin-bottom: 80px;
}
@media only screen and (max-width: 767px) {
  .footer-widget {
    margin-bottom: 50px;
  }
}
.footer-widget .widget-title {
  font-weight: 700;
}
.footer-widget .list-unstyled .info-li {
  padding: 10px 0;
}
.footer-widget.border-end {
  border-right-color: #C7C7D5 !important;
  border-right-color: var(--color-ghost) !important;
  padding-right: 80px;
  margin-right: 40px;
}
@media only screen and (max-width: 1199px) {
  .footer-widget.border-end {
    border: none !important;
    padding-right: 0;
    margin-right: 0;
  }
}

.footer-newsletter .title {
  margin-bottom: 20px;
  letter-spacing: -0.045em;
}

.footer-menu-link ul {
  margin: -8px 0;
}
.footer-menu-link li a {
  color: #525260;
  color: var(--color-body);
  padding: 8px 0;
  font-size: 18px;
  line-height: 1.5;
}
@media only screen and (max-width: 991px) {
  .footer-menu-link li a {
    font-size: 16px;
  }
}
.footer-menu-link li a:hover {
  color: #f82e23;
  color: var(--color-link);
}

.footer-bottom {
  border-top: 1px solid #C7C7D5;
  border-top: 1px solid var(--color-ghost);
  padding: 25px 0;
}

@media only screen and (max-width: 767px) {
  .footer-copyright {
    text-align: center;
    margin-bottom: 10px;
  }
}
.footer-copyright .copyright-text {
  color: #757589;
  color: var(--color-gray-1);
}
.footer-copyright .copyright-text a {
  color: #757589;
  color: var(--color-gray-1);
}
.footer-copyright .copyright-text a:hover {
  color: #ff4338;
  color: var(--color-primary);
}

.footer-bottom-link {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .footer-bottom-link {
    text-align: center;
  }
}
.footer-bottom-link ul {
  margin: 0 -20px;
}
.footer-bottom-link ul li {
  padding: 0 20px;
  display: inline-block;
  position: relative;
}
.footer-bottom-link ul li::after {
  content: "";
  height: 4px;
  width: 4px;
  background-color: #99A1AA;
  background-color: var(--color-gray-4);
  border-radius: 50%;
  position: absolute;
  top: 53%;
  right: -5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.footer-bottom-link ul li:last-child::after {
  display: none;
}
.footer-bottom-link ul li a {
  color: #757589;
  color: var(--color-gray-1);
}
.footer-bottom-link ul li a:hover {
  color: #f82e23;
  color: var(--color-link);
}

/*=======================================================================
5. Dark Style 
=========================================================================*/
/*-------------------------
    Dark Style
-------------------------*/
.active-dark-mode {
  color-scheme: dark;
  --dark-main-body: #212121;
  --dark-bg-color: var(--color-dark);
  --dark-body-text: #D3D3D4;
  --dark-title-light: #D3D3D4;
  --dark-solid-btn: var(--color-ghost);
  --dark-border-gray: var(--color-body);
}
.active-dark-mode body {
  color: var(--dark-body-text);
}
.active-dark-mode p {
  color: var(--dark-body-text);
}
.active-dark-mode h1,
.active-dark-mode h2,
.active-dark-mode h3,
.active-dark-mode h4,
.active-dark-mode h5,
.active-dark-mode h6 {
  color: var(--dark-title-light);
}
.active-dark-mode ul li, .active-dark-mode ol li {
  color: var(--dark-body-text);
}
.active-dark-mode .form-group label {
  color: var(--dark-body-text);
}
.active-dark-mode input,
.active-dark-mode .form-control {
  color: var(--dark-body-text);
  border-color: var(--dark-border-gray);
  /* -- Placeholder -- */
}
.active-dark-mode input:focus,
.active-dark-mode .form-control:focus {
  box-shadow: none;
  border-color: transparent;
  background-color: #171717;
}
.active-dark-mode input::-webkit-input-placeholder, .active-dark-mode .form-control::-webkit-input-placeholder {
  color: var(--dark-body-text);
  /* Firefox */
  opacity: 1;
}
.active-dark-mode input:-ms-input-placeholder, .active-dark-mode .form-control:-ms-input-placeholder {
  color: var(--dark-body-text);
  /* Firefox */
  opacity: 1;
}
.active-dark-mode input::placeholder,
.active-dark-mode .form-control::placeholder {
  color: var(--dark-body-text);
  /* Firefox */
  opacity: 1;
}
.active-dark-mode input:-ms-input-placeholder,
.active-dark-mode .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--dark-body-text);
}
.active-dark-mode input::-ms-input-placeholder,
.active-dark-mode .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--dark-body-text);
}
.active-dark-mode .input-group {
  background-color: #171717;
  box-shadow: none;
}
.active-dark-mode .input-group .mail-icon svg {
  fill: #ffffff;
  fill: var(--color-white);
}
@media only screen and (max-width: 479px) {
  .active-dark-mode .input-group {
    background-color: transparent;
  }
  .active-dark-mode .input-group .form-control:focus {
    background-color: transparent;
    border-color: var(--dark-border-gray);
  }
}
.active-dark-mode .error-msg, .active-dark-mode .success-msg {
  margin-top: 25px;
}
.active-dark-mode .error-msg p, .active-dark-mode .success-msg p {
  margin-bottom: 0;
  font-size: 14px;
}
.active-dark-mode .error-msg p {
  color: #ff0000;
}
.active-dark-mode .success-msg p {
  color: #15c54b;
}
.active-dark-mode .bg-color-light {
  background-color: #292930;
  background-color: var(--color-text-dark);
}
.active-dark-mode .bg-color-mercury {
  background-color: var(--dark-bg-color);
}
.active-dark-mode .main-wrapper {
  background-color: var(--dark-main-body);
}
.active-dark-mode .axil-btn.btn-borderd {
  background-color: #ff4338;
  background-color: var(--color-primary);
  border-color: #ff4338;
  border-color: var(--color-primary);
  color: #ffffff;
  color: var(--color-white);
  overflow: hidden;
}
.active-dark-mode .axil-btn.btn-borderd::after {
  content: "";
  height: 350px;
  width: 350px;
  background-color: #f82e23;
  background-color: var(--color-link);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%) scale(0);
          transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;
}
.active-dark-mode .axil-btn.btn-borderd:hover {
  border-color: #f82e23;
  border-color: var(--color-link);
}
.active-dark-mode .axil-btn.btn-borderd:hover:after {
  -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
          transform: translateY(-50%) translateX(-50%) scale(1);
}
.active-dark-mode .axil-btn.btn-fill-white {
  background-color: #ff4338;
  background-color: var(--color-primary);
  color: #ffffff;
  color: var(--color-white);
  overflow: hidden;
}
.active-dark-mode .axil-btn.btn-fill-white::after {
  content: "";
  height: 350px;
  width: 350px;
  background-color: #f82e23;
  background-color: var(--color-link);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%) scale(0);
          transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;
}
.active-dark-mode .axil-btn.btn-fill-white:hover:after {
  -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
          transform: translateY(-50%) translateX(-50%) scale(1);
}
.active-dark-mode .shape-group-1 .shape img {
  -webkit-filter: brightness(0.2);
          filter: brightness(0.2);
}
.active-dark-mode .shape-group-3 .shape:not(.shape-3) img {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}
.active-dark-mode .shape-group-4 .shape-1 img {
  -webkit-filter: brightness(0.2);
          filter: brightness(0.2);
}
.active-dark-mode .shape-group-6 .shape img {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}
.active-dark-mode .shape-group-7 .shape img {
  -webkit-filter: brightness(0.2);
          filter: brightness(0.2);
}
.active-dark-mode .shape-group-8 .shape-1 img, .active-dark-mode .shape-group-8 .shape-2 img, .active-dark-mode .shape-group-8 .shape-3 img {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}
.active-dark-mode .shape-group-9 .shape img {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}
.active-dark-mode .shape-group-11 .shape img {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}
.active-dark-mode .shape-group-12 .shape-3 img {
  opacity: 0.3;
}
.active-dark-mode .shape-group-13 .shape-1 img {
  -webkit-filter: brightness(0.1);
          filter: brightness(0.1);
}
.active-dark-mode .shape-group-13 .shape-2 img {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}
.active-dark-mode .shape-group-17 .shape img {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}
.active-dark-mode .shape-group-19 .shape {
  opacity: 0.2;
}
.active-dark-mode .shape-group-20 .shape-1 {
  opacity: 0.2;
}
.active-dark-mode .shape-group-20 .shape-2 {
  opacity: 0.2;
}
.active-dark-mode .shape-group-21 .shape-1 img {
  -webkit-filter: brightness(0.1);
          filter: brightness(0.1);
}
.active-dark-mode .shape-group-21 .shape-2 img {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}
.active-dark-mode .slick-dot-nav .slick-dots li button {
  background-color: var(--dark-body-text);
}
.active-dark-mode .slick-dot-nav .slick-dots li.slick-active button {
  background-color: #ff4338;
  background-color: var(--color-primary);
}
.active-dark-mode .mainmenu li a {
  color: var(--dark-body-text);
}
.active-dark-mode .mainmenu li a::before {
  background-color: var(--dark-body-text);
}
.active-dark-mode .mainmenu li a:hover {
  color: var(--dark-title-light);
}
.active-dark-mode .mainmenu .menu-item-has-children a::after {
  color: var(--dark-body-text);
}
.active-dark-mode .mainmenu .menu-item-has-children .axil-submenu {
  background-color: var(--dark-bg-color);
}
.active-dark-mode .mainmenu .menu-item-has-children .axil-submenu li a {
  color: var(--dark-body-text);
}
.active-dark-mode .mainmenu .menu-item-has-children .axil-submenu li a::after {
  background-color: var(--dark-title-light);
}
.active-dark-mode .mainmenu .menu-item-has-children .axil-submenu li a:hover {
  color: var(--dark-title-light);
}
.active-dark-mode .axil-mainmenu.axil-sticky {
  background-color: var(--dark-bg-color);
}
.active-dark-mode .axil-mainmenu.axil-sticky .header-action .social-icon-list li a {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .axil-header .header-navbar .header-logo .light-version-logo {
  display: none;
}
.active-dark-mode .axil-header .header-navbar .header-logo .dark-version-logo {
  display: block;
}
.active-dark-mode .axil-header.header-style-1 .axil-mainmenu.axil-sticky .header-navbar .header-logo .sticky-logo {
  display: none;
}
.active-dark-mode .axil-header.header-style-3 .axil-mainmenu.axil-sticky .header-navbar .header-logo .sticky-logo {
  display: none;
}
.active-dark-mode .axil-header.header-style-3 .header-action .sidemenu-btn .btn-wrap {
  background-color: #464646;
}
.active-dark-mode .axil-header.header-style-3 .header-action .social-icon-list li a {
  color: var(--dark-body-text);
}
.active-dark-mode .axil-header.header-style-4 .mainmenu-nav .mainmenu li a {
  color: var(--dark-body-text);
}
.active-dark-mode .axil-header.header-style-4 .mainmenu-nav .mainmenu li a::after {
  color: var(--dark-body-text);
}
.active-dark-mode .axil-header.header-style-4 .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu li a {
  color: var(--dark-body-text);
}
.active-dark-mode .header-offcanvasmenu {
  background-color: var(--dark-bg-color);
}
.active-dark-mode .header-offcanvasmenu .offcanvas-header .btn-close {
  background-color: var(--dark-body-text);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 14px;
}
.active-dark-mode .header-offcanvasmenu .offcanvas-body .main-navigation li a {
  color: var(--dark-title-light);
}
.active-dark-mode .header-offcanvasmenu .offcanvas-body .main-navigation li a:hover {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .header-offcanvasmenu .offcanvas-body .contact-inner .title {
  color: var(--dark-title-light);
}
.active-dark-mode .header-offcanvasmenu .offcanvas-body .contact-inner .address a {
  color: var(--dark-body-text);
}
.active-dark-mode .header-offcanvasmenu .offcanvas-body .contact-inner .address a:hover {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .header-action .sidemenu-btn .btn-wrap {
  background-color: #525260;
  background-color: var(--color-body);
}
.active-dark-mode .header-action .sidemenu-btn .btn-wrap span {
  background-color: var(--dark-title-light);
}
.active-dark-mode .mobile-nav-header {
  border-bottom-color: var(--dark-border-gray);
}
.active-dark-mode .mobile-nav-header .mobile-menu-close {
  background-color: #ffffff;
  background-color: var(--color-white);
  color: var(--dark-bg-color);
  border: none;
}
.active-dark-mode .mobile-nav-header .mobile-nav-logo .light-mode {
  display: none;
}
.active-dark-mode .mobile-nav-header .mobile-nav-logo .dark-mode {
  display: block;
}
@media only screen and (max-width: 991px) {
  .active-dark-mode .mainmenu-nav {
    background-color: var(--dark-bg-color);
  }
  .active-dark-mode .mainmenu-nav .mainmenu > li {
    border-bottom-color: var(--dark-border-gray);
  }
  .active-dark-mode .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu {
    background-color: transparent;
  }
  .active-dark-mode .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu li a {
    color: var(--dark-body-text);
  }
  .active-dark-mode .mainmenu-nav .mainmenu .menu-item-has-children .axil-submenu li a:hover {
    color: #ff4338;
    color: var(--color-primary);
  }
}
.active-dark-mode .banner .banner-content .title {
  color: var(--dark-title-light);
}
.active-dark-mode .banner .banner-content .subtitle {
  color: var(--dark-body-text);
}
.active-dark-mode .banner .banner-content .axil-btn {
  box-shadow: none;
}
.active-dark-mode .banner .banner-social .social-icon li a {
  color: var(--dark-body-text);
}
.active-dark-mode .banner .banner-social .social-icon li a i {
  color: var(--dark-body-text);
}
.active-dark-mode .banner.banner-style-2 {
  background-color: var(--dark-bg-color);
}
.active-dark-mode .banner.banner-style-4 {
  background: var(--dark-main-body);
}
.active-dark-mode .banner.banner-style-5 .banner-content .axil-btn {
  color: var(--dark-solid-btn);
}
.active-dark-mode .section-heading .title {
  color: var(--dark-title-light);
}
.active-dark-mode .section-heading p {
  color: var(--dark-body-text);
}
.active-dark-mode .heading-light-left p {
  opacity: 1 !important;
}
.active-dark-mode .breadcrum-area {
  background: var(--dark-bg-color);
}
.active-dark-mode .case-study-counterup .single-counterup {
  border-right-color: var(--dark-border-gray);
}
.active-dark-mode .case-study-counterup .counter-title {
  color: var(--dark-body-text);
}
.active-dark-mode .project-table .project-header {
  border-bottom: 1px solid rgb(83, 81, 81);
}
.active-dark-mode .project-table.project-borderd {
  border: 1px solid #42424A;
  border: var(--border-dark);
}
.active-dark-mode .process-work .content p {
  color: var(--dark-body-text);
}
.active-dark-mode .services-grid .content .title a {
  color: inherit;
}
.active-dark-mode .services-grid .content .title a:hover {
  color: #ff4338;
  color: var(--color-primary);
}
.active-dark-mode .services-grid .content p {
  color: var(--dark-body-text);
  opacity: 1;
}
.active-dark-mode .services-grid:hover .content .sub-title span, .active-dark-mode .services-grid.active .content .sub-title span {
  border: none;
}
.active-dark-mode .services-grid.service-style-2 {
  background: var(--dark-bg-color);
}
.active-dark-mode .services-grid.service-style-2 .content .title a {
  color: var(--dark-title-light);
}
.active-dark-mode .services-grid.service-style-2 .content p {
  color: var(--dark-body-text);
}
.active-dark-mode .services-grid.service-style-2 .content .more-btn {
  color: var(--dark-solid-btn);
}
.active-dark-mode .services-grid.service-style-2:hover .content .more-btn, .active-dark-mode .services-grid.service-style-2.active .content .more-btn {
  color: #ff4338;
  color: var(--color-primary);
}
.active-dark-mode .service-scroll-nav {
  background-color: var(--dark-main-body);
}
.active-dark-mode .service-scroll-nav ul {
  border-bottom-color: var(--dark-border-gray);
}
.active-dark-mode .service-scroll-nav ul li a {
  color: var(--dark-body-text);
}
.active-dark-mode .service-scroll-nav ul li a:hover {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .service-scroll-nav ul li a:after {
  background-color: #ffffff;
  background-color: var(--color-white);
}
.active-dark-mode .service-scroll-nav ul li.current a {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .contact-form-box {
  background-color: var(--dark-main-body);
}
.active-dark-mode .contact-form-box.shadow-box {
  box-shadow: 0 20px 48px 0 rgba(0, 0, 0, 0.25);
}
.active-dark-mode .contact-address .address p {
  color: var(--dark-body-text);
}
.active-dark-mode .contact-address .address p a {
  color: var(--dark-body-text);
}
.active-dark-mode .testimonial-grid .author-info .content .name {
  color: var(--dark-title-light);
}
.active-dark-mode .testimonial-grid p {
  color: var(--dark-body-text);
}
.active-dark-mode .followers-list li a span {
  color: var(--dark-body-text);
}
.active-dark-mode .plans-table {
  background-color: var(--dark-main-body);
}
.active-dark-mode .plans-table .plans-header {
  border-bottom: 1px solid rgb(83, 81, 81);
}
.active-dark-mode .plans-table .plans-header .subtitle {
  color: var(--dark-body-text);
}
.active-dark-mode .plans-table:hover .plans-header .subtitle, .active-dark-mode .plans-table.active .plans-header .subtitle {
  color: #ff4338;
  color: var(--color-primary);
}
.active-dark-mode .plans-table.plans-borderd {
  border-color: var(--dark-border-gray);
}
.active-dark-mode .case-list {
  border-top-color: var(--dark-border-gray);
  border-bottom-color: var(--dark-border-gray);
}
.active-dark-mode .case-list .case-content .title {
  color: #ff4338;
  color: var(--color-primary);
}
.active-dark-mode .case-list .case-content p {
  color: var(--dark-body-text);
}
.active-dark-mode .case-list .case-content p span {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .case-list.border-start {
  border-left-color: var(--dark-border-gray) !important;
}
.active-dark-mode .call-to-action-area {
  border: var(--dark-border-gray);
}
.active-dark-mode .call-to-action-area::before {
  background-color: var(--dark-bg-color);
}
.active-dark-mode .call-to-action-area .call-to-action {
  border-color: var(--dark-border-gray);
}
.active-dark-mode .footer-social-link ul li a {
  color: var(--dark-body-text);
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
}
.active-dark-mode .footer-social-link ul li a:hover {
  color: #ff4338;
  color: var(--color-primary);
}
.active-dark-mode .footer-widget.border-end {
  border-right-color: var(--dark-border-gray) !important;
}
.active-dark-mode .footer-menu-link li a {
  color: var(--dark-body-text);
}
.active-dark-mode .footer-menu-link li a:hover {
  color: #ff4338;
  color: var(--color-primary);
}
.active-dark-mode .footer-bottom {
  border-top-color: var(--dark-border-gray);
}
.active-dark-mode .footer-bottom-link ul li a {
  color: var(--dark-body-text);
}
.active-dark-mode .footer-bottom-link ul li a:hover {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .footer-bottom-link ul li::after {
  background-color: var(--dark-body-text);
}
.active-dark-mode .footer-copyright .copyright-text {
  color: var(--dark-body-text);
}
.active-dark-mode .footer-copyright .copyright-text a {
  color: var(--dark-body-text);
}
.active-dark-mode .footer-copyright .copyright-text a:hover {
  color: #ffffff;
  color: var(--color-white);
}
.active-dark-mode .onepage-screen-area {
  background: transparent;
}
.active-dark-mode .onepage-screen-area .content .countdown .countdown-section::after {
  background-color: var(--dark-border-gray);
}
.active-dark-mode .onepage-screen-area .content .countdown .countdown-section .countdown-unit {
  color: var(--dark-body-text);
}
.active-dark-mode .onepage-screen-area.coming-soon-area .site-logo .logo-light {
  display: none;
}
.active-dark-mode .onepage-screen-area.coming-soon-area .site-logo .logo-dark {
  display: block;
}
.active-dark-mode .splash-main-banner {
  background-color: #292930;
  background-color: var(--color-text-dark);
}
.active-dark-mode .splash-main-banner:before {
  background: var(--dark-main-body);
}
.active-dark-mode .splash-main-banner .site-element-count .count-title {
  color: var(--dark-body-text);
}
.active-dark-mode .splash-main-banner .demo-slider .slick-slide a {
  box-shadow: none;
}
.active-dark-mode .main-demo-area .single-demo .thumb {
  box-shadow: none;
}
.active-dark-mode .main-demo-area .shape-group li {
  opacity: 0.1;
}
.active-dark-mode .spalsh-why-choose .why-buy-box {
  background-color: var(--dark-main-body);
  border-color: var(--dark-main-body);
}
.active-dark-mode .spalsh-why-choose .why-buy-box.active {
  background-color: #ff4338;
  background-color: var(--color-primary);
  border-color: #ff4338;
  border-color: var(--color-primary);
}
.active-dark-mode .spalsh-why-choose .line-shape {
  opacity: 0.1;
}
.active-dark-mode .support-box a {
  background-color: var(--dark-main-body);
  border-color: var(--dark-main-body);
}
.active-dark-mode .support-box:hover a, .active-dark-mode .support-box.active a {
  box-shadow: 0 50px 50px -24px rgba(1, 1, 1, 0.3);
}
.active-dark-mode .splash-call-to-action {
  background-color: var(--dark-bg-color);
}

/*=======================================================================
6. Spacing 
=========================================================================*/
/*-------------------------
    Section Space
-------------------------*/
.section-padding {
  padding: 140px 0 110px;
}
@media only screen and (max-width: 1199px) {
  .section-padding {
    padding: 100px 0 70px;
  }
}
@media only screen and (max-width: 991px) {
  .section-padding {
    padding: 80px 0 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding {
    padding: 60px 0 30px;
  }
}

.section-padding-1 {
  padding: 80px 0 50px;
}
@media only screen and (max-width: 1199px) {
  .section-padding-1 {
    padding: 60px 0 40px;
  }
}
@media only screen and (max-width: 991px) {
  .section-padding-1 {
    padding: 40px 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-1 {
    padding: 30px 0 10px;
  }
}

.section-padding-2 {
  padding: 140px 0 100px;
}
@media only screen and (max-width: 1199px) {
  .section-padding-2 {
    padding: 100px 0 60px;
  }
}
@media only screen and (max-width: 991px) {
  .section-padding-2 {
    padding: 80px 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-2 {
    padding: 60px 0 20px;
  }
}

.section-padding-3 {
  padding: 100px 0 100px;
}
@media only screen and (max-width: 1199px) {
  .section-padding-3 {
    padding: 800px 0 60px;
  }
}
@media only screen and (max-width: 991px) {
  .section-padding-3 {
    padding: 60px 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-3 {
    padding: 40px 0 20px;
  }
}

.section-padding-4 {
  padding: 80px 0 120px;
}
@media only screen and (max-width: 1199px) {
  .section-padding-4 {
    padding: 60px 0 100px;
  }
}
@media only screen and (max-width: 991px) {
  .section-padding-4 {
    padding: 40px 0 100px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-4 {
    padding: 30px 0 100px;
  }
}

.section-padding-equal {
  padding: 140px 0;
}
@media only screen and (max-width: 1199px) {
  .section-padding-equal {
    padding: 100px 0;
  }
}
@media only screen and (max-width: 991px) {
  .section-padding-equal {
    padding: 80px 0;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-equal {
    padding: 60px 0;
  }
}

.mt--200 {
  margin-top: 200px;
}

.mt--150 {
  margin-top: 150px;
}

.mt--100 {
  margin-top: 100px;
}

.mt--90 {
  margin-top: 90px;
}

.mt--80 {
  margin-top: 80px;
}

.mt--20 {
  margin-top: 20px !important;
}

.mb--100 {
  margin-bottom: 100px;
}

.mb--90 {
  margin-bottom: 90px;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.pt--250 {
  padding-top: 250px;
}

.pt--200 {
  padding-top: 200px;
}

.pt--150 {
  padding-top: 150px;
}

.pb--90 {
  padding-bottom: 90px;
}

.pb--80 {
  padding-bottom: 80px;
}

.pb--70 {
  padding-bottom: 70px;
}

.plr--30 {
  padding: 0 30px;
}

@media only screen and (max-width: 1199px) {
  .pt_lg--200 {
    padding-top: 200px;
  }

  .pt_lg--100 {
    padding-top: 100px;
  }

  .pb_lg--40 {
    padding-bottom: 40px;
  }

  .pb_lg--20 {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .mb_md--70 {
    margin-bottom: 70px;
  }

  .mb_md--30 {
    margin-bottom: 30px;
  }

  .pt_md--100 {
    padding-top: 100px;
  }

  .pt_md--80 {
    padding-top: 80px;
  }

  .pb_md--70 {
    padding-bottom: 70px;
  }

  .pb_md--20 {
    padding-bottom: 20px;
  }

  .mt_md--0 {
    margin-top: 0;
  }

  .mb_md--0 {
    margin-bottom: 0;
  }

  .pb_md--0 {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .pt_sm--80 {
    padding-top: 80px;
  }

  .pt_sm--60 {
    padding-top: 60px;
  }

  .pb_sm--0 {
    padding-bottom: 0;
  }
}
/*=======================================================================
7. RTL 
=========================================================================*/
