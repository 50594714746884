/*-------------------------
    Service
-------------------------*/

.services-grid {
    padding: 35px 40px 35px;
    border-radius: 30px;
    transition: var(--transition);
    position: relative;
    z-index: 1;
    transition: var(--transition);
    text-align: center;
    // &:after {
    //     content: "";
    //     height: 100%;
    //     width: 100%;
    //     // background: var(--color-primary);
    //     border: 1px solid;
    //     border-image-source: linear-gradient(0deg, #27272E 64.45%, rgba(74, 75, 86, 0.72) 100%);
    //     border-radius: 30px;
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     z-index: -1;
    //     visibility: hidden;
    //     opacity: 0;
    //     transition: var(--transition);
    // }
    .thumbnail {
        max-width: 100%;
        border-radius: 20px;
        img {
            height: 30vh;
        }
    }
    .thumbnail1 {
        background-color: var(--color-design);
    }
    .thumbnail2 {
        background-color: var(--color-mobile)
    }
    .thumbnail3 {
        background-color: var(--color-web-dev)
    }
    .thumbnail4 {
        background-color: var(--color-devOps)
    }
    .content {
        flex: 1;
        .title {
            padding-top: 40px;
            margin-bottom: 10px;
        }
        p {
            color: var(--color-black);
            opacity: 0.5;
            letter-spacing: -0.025em;
            transition: var(--transition);
            margin: 0 0 30px 0;
        }
        .sub-title {
            @media #{$lg-layout} {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            @media #{$md-layout} {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            span {
                border-radius: 12px;
                margin: 5px;
                padding: 10px;
                font-weight: bold;
                font-size: var(--font-body-4);
                @media #{$lg-layout} {
                    display: block;
                    width: 70%;
                }
                @media #{$md-layout} {
                    width: 70%;
                }
            }
        }
        .sub-title1{
            span {
                color: var(--color-design);
                background-color: var(--color-white);
                border:1px solid var(--color-design)
            }
        }
        .sub-title2{
            span {
                color: var(--color-mobile);
                background-color: var(--color-white);
                border:1px solid var(--color-mobile)
            }
        }
        .sub-title3{
            span {
                color: var(--color-web-dev);
                background-color: var(--color-white);
                border:1px solid var(--color-web-dev)
            }
        }
        .sub-title4{
            span {
                color: var(--color-devOps);
                background-color: var(--color-white);
                border:1px solid var(--color-devOps)
            }
        }
        
        
    }
    &:hover, &.active{
        &:after {
            visibility: visible;
            opacity: 1;
        }
        .thumbnail {
            &::after {
                transform: scale(1);
            }
        }
        .content {
            .sub-title1 {
                span {
                    color: var(--color-white);
                    background-color: var(--color-design);
                    border: var(--border-lighter);
                    transition: var(--transition);
                }
            }
            .sub-title2 {
                span {
                    color: var(--color-white);
                    background-color: var(--color-mobile);
                    border: var(--border-lighter);
                    transition: var(--transition);
                }
            }
            .sub-title3 {
                span {
                    color: var(--color-white);
                    background-color:var(--color-web-dev);
                    border: var(--border-lighter);
                    transition: var(--transition);
                }
            }
            .sub-title4 {
                span {
                    color: var(--color-white);
                    background-color: var(--color-devOps);
                    border: var(--border-lighter);
                    transition: var(--transition);
                }
            }
            
        }
    }

}