/*-------------------------
   plans
-------------------------*/
.plans-table {
    background-color: var(--color-whit e);
    padding: 40px;
    border-radius: 30px;
    position: relative;
    margin-bottom: 30px;
    height: 450px;
    transition: var(--transition);
    @media #{$lg-layout} {
        padding: 40px 15px;
    }
    @media #{$large-mobile} {
        padding: 40px 30px;
    }
    .plans-header {
        text-align: center;
        border-bottom: 1px solid rgba(227, 230, 233, 1);
        .title {
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 8px;
            letter-spacing: -0.045em;
            transition: var(--transition);
            @media #{$smlg-device} {
                font-size: 36px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
            }
        }
        .subtitle {
            font-size: 24px;
            font-weight: 600;
            color: var(--color-primary);
            transition: var(--transition);
            margin-bottom: 20px;
        }
    }
    .plans-body {
        margin-top: 30px;
        li {
            padding: 12px 0;
            color: var(--color-gray-2);
            padding-left: 34px;
            position: relative;
            transition: var(--transition);
        }
    }
    &:hover, &.active {
        background-color: var(--color-primary);
        .plans-header {
            .title {
                color: var(--color-white);
            }
            .subtitle {
                color: var(--color-white);
            }
        }
        .plans-body {
            li {
                color: var(--color-white);
            }
        }
    }
    &.plans-borderd {
        border: var(--border-light);
    }
}
