.info {
    @media #{$sm-layout} {
        text-align: center;
    }
    img{
        @media #{$sm-layout} {
            padding-bottom: 2vh;
        }
    }
    .mokup {
        
    }
}

.responsible{
    @media #{$sm-layout} {
        text-align: center;
    }
    span {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 10px;
        margin-right: 5px;
        @media #{$sm-layout} {
            margin-bottom: 8px;
        }
    }
}
.challenge{
    @media #{$sm-layout} {
        text-align: center;
    }
}

.screen-slider {
    h4{
        padding-top: 12vh;
    }
    img {
        margin: 0 auto;
        height: 60%;
        width: 60%;
    }
}

.app-features {
    @media #{$sm-layout} {
        text-align: center;
    }
    
    background-color: #0ba55b;
    .carousel__slider{
        // height: 50vh;
    }
    .cont {
        // background-color: #fff;
        // padding: 5vh;
        h4 {
            color: #fff;
            margin: 0;
        }
    }
    
    // .btn-back, .btn-next {
    //     border-radius: 50%;
    //     padding: 15px;
    //     margin-right: 5px;
    //     border: 1px;
    //     background-color: green;
    //     color: white;
    // }
    .feature-item {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 15px;
        margin-top: 6vh;
        max-width: 85%;
        padding: 5vh 3vh;
        h5 {
            text-align: left;
            color: #0ba55b
        }
        p {
            text-align: left;
        }
        
    }
}
.lampa {
    background-color: #5FB2EE;
    .feature-item {
        h5 {
            color: #5FB2EE;
        }
    }
}
.EnoughTea {
    background-color: #2e9bff;
    .feature-item {
        h5 {
            color: #2e9bff;
        }
    }
}

.testimoial {
    height: 80vh;
    margin-bottom: 10vh;
    display: flex;
    align-items: center;
    .container{
        .sub-container{
            background-color: #fff;
            border-radius: 30px;
            @media #{$sm-layout} {
                margin-top: 5vh;
            }
            // height: 60vh;
            h5{
                padding: 5vh 0 1vh;
                @media #{$sm-layout} {
                    padding: 5vh 0 5vh;
                    margin: 0;
                }
            }
            img{
                margin: 0 auto;
            }
            span{
                padding-top: 1vh;
                font-size: 0.8em;
            }
            .role{
                display: block;
            }
            p{
                padding: 5vh;
            }
            
        }

    }
}
.test {
    width: 35%;
}
.carousel-control-prev-icon-test{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2376797b'  viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    
}
.carousel-control-next-icon-test {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2376797b' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

@media only screen and (max-width: 991px) {
    .carousel-control-prev,
    .carousel-control-next {
        top: 0
    }
}