/*-------------------------
   Brand
-------------------------*/

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
}
video {
    object-fit: cover;
    width: 100%;
    height: 80vh;
}
.text-on-video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        color: var(--color-white);
    }
    p {
        color: var(--color-white);
    }
}

