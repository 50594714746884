/*-------------------------
    Case Studies Styles
-------------------------*/
.case-list {
    display: flex;
    align-items: center;
    border-top: var(--border-light);
    border-bottom: var(--border-light);
    padding: 40px 20px 40px 0;
    transition: var(--transition);
    @media #{$smlg-device} {
        padding: 40px 0;
    }
    @media #{$sm-layout} {
       display: block;
     }
    .case-thumbnail {
        @media #{$sm-layout} {
            margin-bottom: 30px;
            text-align: center;
        }
        a {
            border-radius: 30px;
            overflow: hidden;
            display: block;
            img {
                border-radius: 30px;
                transition: var(--transition);
                @media #{$sm-layout} {
                    width: 100%;
                }
            }
        }
    }
    .case-content {
        flex: 1;
        margin-left: 20px;
        @media #{$sm-layout} {
           margin-left: 0;
        }
        .title {
            margin-bottom: 20px;
        }
        p {
            color: var(--color-gray-1);
            letter-spacing: -0.025em;
            margin-bottom: 10px;
            span {
                color: var(--color-text-dark);
                margin-right: 10px;
            }
        }
    }
    &:hover, &.active {
        border-top-color: var(--color-primary);
        .case-thumbnail {
            img {
                // transform: scale(1.1);
            }
        }
    }
    
}