/*-------------------------
   Project
-------------------------*/
.carousel-control-next {
    @media #{$md-layout} {
        top: 20vh
    }
}
.carousel-control-prev {
    @media #{$md-layout} {
        top: 20vh
    }
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 8 8'><path d='M2.75 0l-1 1 2 2-2 2 1 1 3-3-3-3z'/></svg>");
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-3 3 3 3 1-1-2-2 2-2-1-1z'/%3E%3C/svg%3E");
}
.carousel-indicators {
    visibility: hidden;
}
.project-table {
    padding: 30px 0 40px;
    border-radius: 30px;
    position: relative;
    margin-bottom: 30px;
    transition: var(--transition);
    @media #{$lg-layout} {
        padding: 40px 15px;
    }
    @media #{$large-mobile} {
        padding: 40px 10px;
    }
    .project-header { // Edite by Mahmoud
        text-align: center;
        border-bottom: 1px solid rgba(227, 230, 233, 1);
        .title {
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 30px;
            letter-spacing: -0.045em;
            transition: var(--transition);
            @media #{$smlg-device} {
                font-size: 36px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
            }
        }
    }
    
    .project-body {
        margin-top: 30px;
        li {
            padding: 12px 0;
            color: var(--color-gray-2);
            padding-left: 34px;
            position: relative;
            transition: var(--transition);
            @media #{$small-mobile} {
                padding-left: 14px;
            }
        }
    }
    &:hover, &.active {
        background-color: var(--color-primary);
        .project-header {
            .title {
                color: var(--color-white);
            }
        }
        .project-body {
            li {
                color: var(--color-white);
            }
        }
    }
    &.project-borderd {
        border: var(--border-light);
    }
}

// added by mahmoud
.carousel-inner {
    width: 75%;
    margin: 0 auto;
}
.test-class {
    text-align: center;
    margin: auto;
    h2 {
        font-size: 100px;
    }
}